<template>
  <ValidationObserver :key="id" ref="form" v-slot="{ handleSubmit, reset }">
    <loading
      :active.sync="showLoadingSpinner"
      :color="'#00b8ee'"
      :is-full-page="true"
      blur="0"
    >
    </loading>
    <!-- <div class="maintenance-notice">
          <p>
            Our Client portal is undergoing maintenance now.
            We request you to not register/login using this portal. We apologize for any inconvenience
            and thank you for your understanding.
          </p>
        </div> -->
    <form
      v-if="showTheForm"
      :id="id"
      :number-of-steps="registrationNumberOfSteps"
      :step="id"
      @submit.prevent="handleSubmit(next), (showRequired = true)"
    >
      <div :class="classes" class="bg-white rounded-md shadow p-6">
        <div v-if="whitelabel === 'TD365' && checkStepNumber() == 3">
          <div class="flex items-center mb-8">
            <svg
              aria-hidden="true"
              class="h-5 w-5 text-blue-400 flex-shrink-0 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clip-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                fill-rule="evenodd"
              />
            </svg>
            <div class="font-normal text-gray-700 text-sm">
              <span class="font-bold"
                >Please take the time to answer these questions correctly.</span
              >
              <p>
                Your answers will be used to assess whether we can provide you
                with a live trading account
              </p>
            </div>
          </div>
        </div>
        <div
          v-for="(section, index) in step"
          :key="index"
          :class="step.indexOf(section) === 0 ? '' : 'mt-12'"
        >
          <div class="mb-6">
            <h3 class="text-lg leading-6 font-normal text-gray-900">
              {{ checkTitle(section.title) }}
            </h3>
            <politically-exposed-modal
              v-if="showPoliticallyExposed"
              @closeModal="showPoliticallyExposed = false"
            ></politically-exposed-modal>
            <div
              v-if="section.title === 'Politically exposed person'"
              class="flex justify-between"
            >
              <p class="mt-1 text-sm leading-5 text-gray-500 html-a">
                <span v-html="sanitizeText(section.subtitle)"></span>
              </p>
              <button
                class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-sm text-gray-500 rounded bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                type="button"
                @click="showPoliticallyExposed = !showPoliticallyExposed"
              >
                Info
              </button>
            </div>

            <p
              v-else-if="
                whitelabel === 'ICMMENA' &&
                section.fields[0] === 'terms_and_conditions'
              "
              class="mt-1 text-sm leading-5 text-gray-500 html-a"
            >
              <span
                v-html="
                  sanitizeText(
                    $t(
                      'by-submitting-below-i-agree-to-icm-limited-terms-and-conditions'
                    )
                  )
                "
              ></span>
            </p>
            <p
              v-else-if="
                whitelabel === 'ICMCapital' &&
                section.subtitle ===
                  'You will need to hold a meeting of the Board of Directors of the Company in order to pass certain resolutions to authorize your transactions in margined trading with ICM Capital Limited.'
              "
              class="mt-1 text-sm leading-5 text-gray-500 html-a"
            >
              <span
                v-html="
                  sanitizeText(
                    $t(
                      'you-will-need-to-hold-a-meeting-of-the-board-of-directors'
                    )
                  )
                "
              ></span>
            </p>
            <p
              v-else-if="
                whitelabel === 'ICMCapital' &&
                section.subtitle === 'The Company’s investment objective is to:'
              "
              class="mt-1 text-sm leading-5 text-gray-500 html-a"
            >
              <span
                v-html="
                  sanitizeText($t('the-companys-investment-objective-is-to'))
                "
              ></span>
            </p>
            <p
              v-else-if="
                whitelabel === 'ICMCapital' &&
                section.subtitle.includes(
                  'By submitting this application, I, {first_name} {last_name} of {company_name} certify to\n        ICM Capital that following resolutions were duly passed by the directors of'
                )
              "
              class="mt-1 text-sm leading-5 text-gray-500 html-a"
            >
              <span
                v-html="
                  sanitizeText(
                    $t(
                      'by-submitting-this-application-i-of-certify-to-icm-capital-that-following-resolutions'
                    )
                  )
                "
              ></span>
            </p>
            <p
              v-else-if="
                whitelabel === 'ICMCapital' &&
                section.subtitle.includes(
                  'I agree to be classified as an Elective professional client under FCA rules.'
                )
              "
              class="mt-1 text-sm leading-5 text-gray-500 html-a"
            >
              <span
                v-html="
                  sanitizeText(
                    $t(
                      'by-submitting-this-application-i-agree-to-be-classified-as-an-elective-professional-client-under-fca-rules'
                    )
                  )
                "
              ></span>
            </p>
            <p v-else class="mt-1 text-sm leading-5 text-gray-500 html-a">
              <span v-html="sanitizeText(section.subtitle)"></span>
            </p>
            <span
              v-if="
                section.fields[1] === 'company_trade_risks' &&
                showRequiredMessage
              "
              id="investment-objective"
              class="text-sm text-red-600 h-4"
              >{{ $t("this-field-is-required") }}</span
            >
            <p class="mt-1 text-sm leading-5 text-gray-500 html-a">
              <span v-html="sanitizeText(section.notice)"></span>
            </p>
            <p
              v-if="
                whitelabel === 'ICMMENA' &&
                section.fields[0] === 'terms_and_conditions'
              "
              class="mt-1 text-sm leading-5 text-gray-500 html-a"
            >
              <span
                v-html="
                  sanitizeText(
                    $t(
                      'by-submitting-this-registration-form-you-are-consenting-to-receive-icmmena'
                    )
                  )
                "
              ></span>
            </p>
            <p
              v-else-if="
                whitelabel === 'ICMCapital' &&
                section.title === 'Elective Professional qualitative assessment'
              "
              class="mt-1 text-sm leading-5 text-gray-500 html-a"
            >
              <span
                v-html="
                  sanitizeText(
                    $t(
                      'in-order-to-open-a-live-trading-account-with-icm-capital'
                    )
                  )
                "
              ></span>
            </p>
            <p
              v-else-if="
                whitelabel === 'ICMCapital' &&
                section.footer.includes(
                  'By submitting this registration form you are consenting to receive information from ICM Capital\n        about offers, products and services which we believe may be of interest to you.'
                )
              "
              class="mt-1 text-sm leading-5 text-gray-500 html-a"
            >
              <span
                v-html="
                  sanitizeText(
                    $t(
                      'by-submitting-this-registration-form-you-are-consenting-to-receive-information-from-icm-capital-about-offers'
                    )
                  )
                "
              ></span>
            </p>
            <p v-else class="mt-1 text-sm leading-5 text-gray-500 html-a">
              <span v-html="sanitizeText(section.footer)"></span>
            </p>
          </div>
          <div class="grid grid-cols-4 gap-x-4">
            <div
              v-for="(field, index) in formType === 'IB-individual' ||
              formType === 'IB-company' ||
              formType === 'annual-check'
                ? forms
                : section.fields
                    .map(
                      Map.prototype.get,
                      new Map(forms.map((o) => [o.key, o]))
                    )
                    .filter((item) => {
                      return item !== undefined;
                    })"
              :key="index"
              :class="
                field.key === 'first_name' ||
                field.key === 'last_name' ||
                field.key === 'addr_zip' ||
                field.key === 'middle_initial' ||
                field.half ||
                field.key === 'date_of_birth'
                  ? 'col-span-4 sm:col-span-2'
                  : 'col-span-4' &&
                    field.key === 'email' &&
                    whitelabel !== 'VCGMarkets'
                  ? 'col-span-4 sm:col-span-3'
                  : 'col-span-4'
              "
            >

            <!-- If input is Email field  BR#21 Mbeng Atemson 28/08/2024 autoCorrect Email Domain -->
              <ValidationProvider
                v-if="field.key === 'email'"
                v-show="shouldShowEmail"
                v-slot="{ errors, reset }"
                :name="field.key"
                :rules="{ required: true, email: true }"
                ref="emailProvider"
              >
                <div class="text-sm leading-5 pt-4">
                  <label :for="field.kind" class="font-normal text-gray-700">{{ checkLabel(field.label) }}</label>
                  <span v-if="field.needed" class="text-sm text-red-600 h-4">*</span>
                </div>
                <div class="mt-1 rounded-md shadow-sm">
                  <input
                    :id="field.id"
                    v-model="form[field.key]"
                    @blur="() => { handleBlur(); reset(); }"
                    :placeholder="field.placeholder ? field.placeholder : field.label"
                    :type="field.kind"
                    class="form-input inline-block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    @input="updateForm(form, $event.target.value)"
                  />
                </div>
                <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
              </ValidationProvider>

              <!--              If input is password-->
              <ValidationProvider
                v-if="field.key === 'password'"
                v-slot="{ errors }"
                :rules="{ required: true, regex: passwordPattern }"
                name="confirm"
              >
                <div class="text-sm leading-5 pt-4">
                  <label :for="field.kind" class="font-normal text-gray-700"
                    >{{ checkLabel(field.label) }}
                  </label>
                  <span v-if="field.needed" class="text-sm text-red-600 h-4">
                    *
                  </span>
                </div>
                <div class="mt-1 rounded-md shadow-sm">
                  <input
                    :id="field.id"
                    v-model="form[field.key]"
                    :placeholder="
                      field.placeholder ? field.placeholder : field.label
                    "
                    :type="field.kind"
                    autocomplete="new-password"
                    class="form-input inline-block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    @input="updateForm(form, $event.target.value)"
                  />
                </div>
                <span
                  v-if="
                    errors[0] === 'confirm' &&
                    (whitelabel === 'ICMVC' ||
                      whitelabel === 'ICMAP' ||
                      whitelabel === 'ICMMU' ||
                      whitelabel === 'ICMCapital' ||
                      whitelabel === 'OTM' ||
                      whitelabel === 'ICMMENA' ||
                      whitelabel === 'ICMTrader' ||
                      whitelabel === 'VCGMarkets' ||
                      whitelabel === 'GCCBrokers' ||
                      whitelabel === 'PolarisMarkets')
                  "
                  class="text-sm text-red-600 h-4"
                >
                  {{
                    $t(
                      "password-must-be-8-to-15-characters-long-contain-four-character-types-lowercase-letters-uppercase-letters-numbers-and-symbols"
                    )
                  }}
                </span>
                <span
                  v-else-if="errors[0] === 'confirm'"
                  class="text-sm text-red-600 h-4"
                >
                  {{
                    $t(
                      "password-must-contain-minimum-8-characters-including-one-uppercase-letter-and-one-number"
                    )
                  }}
                </span>
                <span v-else class="text-sm text-red-600 h-4">{{
                  errors[0]
                }}</span>
              </ValidationProvider>

              <!--    If input is password confirmation-->

              <ValidationProvider
                v-if="field.key === 'password_confirmation'"
                v-slot="{ errors }"
                rules="password:@confirm|required"
              >
                <div class="text-sm leading-5 pt-4">
                  <label :for="field.kind" class="font-normal text-gray-700"
                    >{{ field.label }}
                  </label>
                  <span v-if="field.needed" class="text-sm text-red-600 h-4">
                    *
                  </span>
                </div>
                <div class="mt-1 rounded-md shadow-sm">
                  <input
                    :id="field.id"
                    v-model="form[field.key]"
                    :placeholder="
                      field.placeholder ? field.placeholder : field.label
                    "
                    :type="field.kind"
                    class="form-input inline-block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    @input="updateForm(form, $event.target.value)"
                  />
                </div>
                <span
                  v-if="errors[0] === 'Passwords do not match'"
                  class="text-sm text-red-600 h-4"
                >
                  {{
                    $t("your-passwords-do-not-match-please-check-and-try-again")
                  }}</span
                >
                <span v-else class="text-sm text-red-600 h-4">{{
                  errors[0]
                }}</span>
              </ValidationProvider>

              <!--              If input is  expected_deposit this will be only for icmcapital and icmmu-->

              <!--              <ValidationProvider name="Minimum deposit is 200 USD."-->
              <!--                                  rules="required|min_value:200"-->
              <!--                                  v-slot="{ errors }"-->
              <!--                                  v-if="field.key === 'expected_deposit'">-->
              <!--                <div class="text-sm leading-5 pt-4">-->
              <!--                  <label :for="field.kind"-->
              <!--                         class="font-normal text-gray-700">{{ field.label }}</label>-->
              <!--                </div>-->
              <!--                <div class="mt-1 rounded-md shadow-sm">-->
              <!--                  <input :id="field.id"-->
              <!--                         :placeholder="field.placeholder ? field.placeholder : field.label"-->
              <!--                         :type="field.kind"-->
              <!--                         @input="updateForm(form, $event.target.value)"-->
              <!--                         v-model="form[field.key]"-->
              <!--                         class="form-input inline-block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5">-->
              <!--                </div>-->
              <!--                <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>-->
              <!--              </ValidationProvider>-->

              <!--              If input is date of birth-->

              <ValidationProvider
                v-if="
                  field.key === 'date_of_birth' ||
                  field.key === 'company_director_date_of_birth'
                "
                v-slot="{ errors }"
                :name="$t('please-enter-a-valid-date')"
                :rules="{
                  required: true,
                  minAgeRestriction: true,
                  maxAgeRestriction: true,
                  regex:
                    /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d\d/,
                }"
              >
                <div class="text-sm leading-5 pt-4">
                  <label :for="field.kind" class="font-normal text-gray-700">
                    {{ $t("date-of-birth") }}
                  </label>
                  <span v-if="field.needed" class="text-sm text-red-600 h-4">
                    *
                  </span>
                </div>
                <div class="mt-1 rounded-md shadow-sm relative">
                  <input
                    :id="field.id"
                    v-model="form[field.key]"
                    :placeholder="$t('date-of-birth')"
                    :type="field.kind"
                    class="form-input inline-block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    maxlength="10"
                    oninput="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                    size="10"
                    @input="updateForm(form, $event.target.value)"
                  />
                  <input
                    v-model="form[field.key]"
                    :class="
                      $store.getters.get_lang === 'ar' ||
                      $store.getters.get_lang === 'fa'
                        ? 'left-2'
                        : 'right-2'
                    "
                    class="absolute top-2 z-20 k-datepicker"
                    type="date"
                    @input="addDate($event.target.value, field.key)"
                  />
                  <span
                    :class="
                      $store.getters.get_lang === 'ar' ||
                      $store.getters.get_lang === 'fa'
                        ? 'left-3'
                        : 'right-3'
                    "
                    class="absolute top-2"
                  >
                    <svg
                      class="w-5 h-5"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      ></path>
                    </svg>
                  </span>
                </div>
                <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
              </ValidationProvider>

              <ValidationProvider
                v-if="field.key === 'company_resolution_date'"
                v-slot="{ errors }"
                :name="$t('please-enter-a-valid-date')"
                :rules="{
                  required: field.needed,
                  futureDate: true,
                  regex:
                    /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d\d/,
                }"
              >
                <div class="text-sm leading-5 pt-4">
                  <label
                    v-if="field.key === 'company_resolution_date'"
                    :for="field.kind"
                    class="font-normal text-gray-700"
                  >
                    {{ $t("meeting-date") }}
                  </label>
                  <span v-if="field.needed" class="text-sm text-red-600 h-4">
                    *
                  </span>
                </div>
                <div class="mt-1 rounded-md shadow-sm relative">
                  <input
                    :id="field.id"
                    v-model="form[field.key]"
                    :placeholder="
                      field.placeholder ? field.placeholder : $t('meeting-date')
                    "
                    :type="field.kind"
                    class="form-input inline-block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    maxlength="10"
                    oninput="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                    size="10"
                    @input="updateForm(form, $event.target.value)"
                  />
                  <input
                    v-model="form[field.key]"
                    :class="
                      $store.getters.get_lang === 'ar' ||
                      $store.getters.get_lang === 'fa'
                        ? 'left-2'
                        : 'right-2'
                    "
                    class="absolute top-2 z-20 k-datepicker"
                    type="date"
                    @input="addDate($event.target.value, field.key)"
                  />
                  <span
                    :class="
                      $store.getters.get_lang === 'ar' ||
                      $store.getters.get_lang === 'fa'
                        ? 'left-3'
                        : 'right-3'
                    "
                    class="absolute top-2"
                  >
                    <svg
                      class="w-5 h-5"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      ></path>
                    </svg>
                  </span>
                </div>
                <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
              </ValidationProvider>

              <ValidationProvider
                v-if="field.key === 'company_date'"
                v-slot="{ errors }"
                :name="$t('please-enter-a-valid-date')"
                :rules="{
                  required: field.needed,
                  regex:
                    /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d\d/,
                }"
              >
                <div class="text-sm leading-5 pt-4">
                  <label
                    v-if="field.key === 'company_date'"
                    :for="field.kind"
                    class="font-normal text-gray-700"
                  >
                    {{ field.label }}
                  </label>
                  <span v-if="field.needed" class="text-sm text-red-600 h-4">
                    *
                  </span>
                </div>
                <div class="mt-1 rounded-md shadow-sm relative">
                  <input
                    :id="field.id"
                    v-model="form[field.key]"
                    :placeholder="field.label"
                    :type="field.kind"
                    class="form-input inline-block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    maxlength="10"
                    oninput="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                    size="10"
                    @input="updateForm(form, $event.target.value)"
                  />
                  <input
                    v-model="form[field.key]"
                    :class="
                      $store.getters.get_lang === 'ar' ||
                      $store.getters.get_lang === 'fa'
                        ? 'left-2'
                        : 'right-2'
                    "
                    class="absolute top-2 z-20 k-datepicker"
                    type="date"
                    @input="addDate($event.target.value, field.key)"
                  />
                  <span
                    :class="
                      $store.getters.get_lang === 'ar' ||
                      $store.getters.get_lang === 'fa'
                        ? 'left-3'
                        : 'right-3'
                    "
                    class="absolute top-2"
                  >
                    <svg
                      class="w-5 h-5"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      ></path>
                    </svg>
                  </span>
                </div>
                <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
              </ValidationProvider>

              <!--              If input is phone number-->

              <ValidationProvider
                v-if="field.key === 'telephone'"
                v-slot="{ errors }"
                :name="field.key"
                :rules="{ required: true, validatePhone: !phoneValid }"
              >
                <div class="text-sm leading-5 pt-4">
                  <label :for="field.kind" class="font-normal text-gray-700">{{
                    checkLabel(field.label)
                  }}</label>
                  <span v-if="field.needed" class="text-sm text-red-600 h-4">
                    *
                  </span>
                </div>
                <div class="rounded-md shadow-sm">
                  <VuePhoneNumberInput
                    id="phone_input"
                    v-model="phone"
                    :border-radius="6"
                    :fetch-country="true"
                    :no-example="true"
                    :translations="{
                      phoneNumberLabel: $t(
                        'phone-number-1f8261d17452a959e013666c5df45e07'
                      ),
                    }"
                    color="#a4cafe"
                    error-color="#E53E3E"
                    valid-color="#d2d6dc"
                    @update="
                      phoneValidateCheck($event),
                        (phone = $event),
                        phone.phoneNumber && phone.phoneNumber.length > 0
                          ? (showRequired = true)
                          : ''
                    "
                  />
                </div>
                <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
              </ValidationProvider>

              <!-- 
              <div v-if="field.key === 'trading_platform' && whitelabel === 'ICMMENA'">
                <ValidationProvider :name="field.key"
                                    :rules="{required: field.needed}"
                                    v-slot="{ errors }">
                  <div class="text-sm leading-5 pt-4">
                    <label :for="field.kind">
                      {{ field.label }}
                    </label>
                    <span class="text-sm text-red-600 h-4" v-if="field.needed">
                      *
                    </span>
                  </div>
                  <v-select label="name"
                            :options="tradingPlatforms.map(item =>  item.backend)"
                            v-model="trading_accounts[0].backend"
                            @input="updateTradingAccountsPlatform"
                            :placeholder="$t('select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11')"
                            :reduce="item => item.name"
                            class="style-chooser"
                            :dir="checkLanguageDirection === 'rtl' ? 'rtl' : 'auto' ">
                  </v-select>
                  <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
                </ValidationProvider>
              </div> -->

              <!--              If input is trading account-->

              <div
                v-if="
                  field.key === 'trading_accounts' &&
                  whitelabel !== 'OrbitInvest' &&
                  whitelabel !== 'FXViewGlobal' &&
                  whitelabel !== 'FXViewEU' &&
                  whitelabel !== 'AAAFXGlobal' &&
                  whitelabel !== 'AAAFXEU' &&
                  whitelabel !== 'Zulu'
                "
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="field.key"
                  :rules="{ required: field.needed }"
                >
                  <div class="text-sm leading-5 pt-4">
                    <label :for="field.kind">
                      {{ checkLabel(field.label) }}
                    </label>
                    <span v-if="field.needed" class="text-sm text-red-600 h-4">
                      *
                    </span>
                  </div>
                  <v-select
                    v-model="trading_accounts[0].backend"
                    :dir="checkLanguageDirection === 'rtl' ? 'rtl' : 'auto'"
                    :options="whitelabel === 'ICMMU' //BR#22 Mbeng 08/09/2024 to hide the demo backends from the drop down list
                    ?tradingPlatforms.filter(item => item.backend.mode === 'Live').map(item => item.backend)
                    :tradingPlatforms.map(item => item.backend)"
                    :placeholder="
                      $t('select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11')
                    "
                    :reduce="(item) => item.name"
                    class="style-chooser"
                    label="name"
                    @input="updateTradingAccountsPlatform"
                  >
                  </v-select>
                  <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
                </ValidationProvider>
                <!-- egmDemo is to check if this is EagleGlobalMarkets demo registration first step,
                      because there, we dont open currency field when trading account is selected -->
                <ValidationProvider
                  v-if="
                    trading_accounts[0].backend.length > 0 && egmDemo === false
                  "
                  v-slot="{ errors }"
                  :name="field.key"
                  :rules="{ required: field.needed }"
                >
                  <div class="text-sm leading-5 mt-5">
                    <label
                      v-if="
                        whitelabel === 'TD365' ||
                        whitelabel === 'TDSouthAfrica' ||
                        whitelabel === 'Blackstone' ||
                        whitelabel === 'EagleGlobalMarkets' ||
                        whitelabel === 'TradeCoreUK'
                      "
                      :for="field.kind"
                    >
                      Trading currency
                    </label>
                    <label v-else :for="field.kind">{{ $t("Currency") }}</label>
                    <span v-if="field.needed" class="text-sm text-red-600 h-4">
                      *
                    </span>
                  </div>
                  <v-select
                    v-model="form.trading_accounts[0].currency"
                    :dir="checkLanguageDirection === 'rtl' ? 'rtl' : 'auto'"
                    :options="
                      tradingPlatforms.find(
                        (x) => x.backend.name === trading_accounts[0].backend
                      ).currencies
                    "
                    :placeholder="
                      $t('select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11')
                    "
                    :reduce="(item) => item"
                    class="style-chooser"
                    @input="updateTradingAccounts"
                  >
                  </v-select>

                  <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider
                  v-if="
                    trading_accounts[0].backend.length > 0 &&
                    egmDemo === false &&
                    whitelabel === 'PolarisMarkets'
                  "
                  v-slot="{ errors }"
                  :name="field.key"
                  :rules="{ required: field.needed }"
                >
                  <div class="text-sm leading-5 mt-5">
                    <label
                      v-if="
                        whitelabel === 'TD365' ||
                        whitelabel === 'TDSouthAfrica' ||
                        whitelabel === 'Blackstone' ||
                        whitelabel === 'EagleGlobalMarkets' ||
                        whitelabel === 'TradeCoreUK'
                      "
                      :for="field.kind"
                    >
                      Trading type
                    </label>

                    <label v-else :for="field.kind">{{ $t("Type") }}</label>
                    <span v-if="field.needed" class="text-sm text-red-600 h-4">
                      *
                    </span>
                  </div>
                  <v-select
                    v-model="form.trading_accounts[0].account_type"
                    :dir="checkLanguageDirection === 'rtl' ? 'rtl' : 'auto'"
                    :options="
                      tradingPlatforms
                        .find(
                          (x) => x.backend.name === trading_accounts[0].backend
                        )
                        ?.account_types?.map((item) => ({
                          value: item,
                          label:
                            item === 'MARGIN' ? 'Position Based' : 'Net Based',
                        }))
                    "
                    :placeholder="$t('select-an-option')"
                    :reduce="(item) => item.value"
                    class="style-chooser"
                    @input="updateTradingAccounts"
                  >
                  </v-select>

                  <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <!-- on open live account / second step
              "account type" filed is conditional to show only when trading platform is selected
              this is for ICMAP ICMVC ICMMU ICMTrader 28/10/2024 # BR#22--> 
              <ValidationProvider
                v-if="
                  field.kind === 'select' &&
                  trading_accounts[0].backend.length > 0 &&
                  (whitelabel === 'ICMAP') &&
                  field.key === 'account_type'
                "
                v-slot="{ errors }"
                :name="field.key"
                :rules="{ required: field.needed }"
              >
                <div class="text-sm leading-5 pt-4 text-gray-700">
                  <label v-if="field.label === 'Title'" :for="field.kind">
                    {{ $t("Title") }}
                  </label>
                  <label v-else :for="field.kind">
                    {{ field.label }}
                  </label>
                  <span v-if="field.needed" class="text-sm text-red-600 h-4">
                    *
                  </span>
                </div>
                <v-select
                  v-model="form[field.key]"
                  :clearable="false"
                  :dir="checkLanguageDirection === 'rtl' ? 'rtl' : 'auto'"
                  :options="
                    field.choices.filter((item) => {
                      return item.display_name !== '';
                    })
                  "
                  :placeholder="
                    $t('select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11')
                  "
                  :reduce="(item) => item.value"
                  class="style-chooser"
                  label="display_name"
                  @input="updateForm"
                ></v-select>
                <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
              </ValidationProvider>
              <!-- on open live account / second step
              "account type" hide ICM Micro from dropdown for platform is selected
              this is for ICMMU - 28/10/2024 # BR#22-->
              <!-- on open live account / second step
              "account type" hide ICM Zero from dropdown for platform is selected
              this is for ICMMU - 20/11/2024 # BR#27-->
              <ValidationProvider
                v-if="
                  field.kind === 'select' &&
                  trading_accounts[0].backend.length > 0 &&
                  (whitelabel === 'ICMMU' ||
                  whitelabel === 'ICMVC' ||
                  whitelabel === 'ICMTrader') &&
                  field.key === 'account_type'
                "
                v-slot="{ errors }"
                :name="field.key"
                :rules="{ required: field.needed }"
              >
                <div class="text-sm leading-5 pt-4 text-gray-700">
                  <label v-if="field.label === 'Title'" :for="field.kind">
                    {{ $t("Title") }}
                  </label>
                  <label v-else :for="field.kind">
                    {{ field.label }}
                  </label>
                  <span v-if="field.needed" class="text-sm text-red-600 h-4">
                    *
                  </span>
                </div>
                <v-select
                  v-model="form[field.key]"
                  :clearable="false"
                  :dir="checkLanguageDirection === 'rtl' ? 'rtl' : 'auto'"
                 
                  :options="
                    field.choices.filter((item) => {
                      return item.display_name !== 'ICM Micro' && item.display_name !== '' && item.display_name !== 'ICM Zero';
                    })
                  "
                  :placeholder="
                    $t('select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11')
                  "
                  :reduce="(item) => item.value"
                  class="style-chooser"
                  label="display_name"
                  @input="updateForm"
                ></v-select>
                <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
              </ValidationProvider>

              <!-- If input is annual check country -->
              <ValidationProvider
                v-if="field.field === 'annual_check_country'"
                v-slot="{ errors }"
                :name="field.key"
                :rules="{ required: field.needed }"
              >
                <div class="text-sm leading-5 pt-4 text-gray-700">
                  <label :for="field.kind">
                    {{ field.label }}
                  </label>
                  <span v-if="field.needed" class="text-sm text-red-600 h-4">
                    *
                  </span>
                </div>
                <v-select
                  v-model="form[field.key]"
                  :clearable="false"
                  :options="field.choices"
                  :placeholder="
                    $t('select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11')
                  "
                  :reduce="(item) => item.iso_code"
                  class="style-chooser"
                  label="name"
                  @input="updateForm"
                ></v-select>
                <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
              </ValidationProvider>

              <!--              If input is ib country select menu-->

              <ValidationProvider
                v-if="
                  (field.field === 'addr_country_ib' ||
                    field.field === 'nationality_ib' ||
                    field.key === 'country_of_incorporation') &&
                  whitelabel !== 'EagleGlobalMarkets' &&
                  whitelabel !== 'TradeCoreUK'
                "
                v-slot="{ errors }"
                :name="field.key"
                :rules="{ required: field.needed }"
              >
                <div class="text-sm leading-5 pt-4 text-gray-700">
                  <label :for="field.kind">
                    {{ field.label }}
                  </label>
                  <span v-if="field.needed" class="text-sm text-red-600 h-4">
                    *
                  </span>
                </div>
                <v-select
                  v-model="form[field.key]"
                  :clearable="false"
                  :dir="checkLanguageDirection === 'rtl' ? 'rtl' : 'auto'"
                  :options="field.choices"
                  :placeholder="
                    $t('select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11')
                  "
                  :reduce="(item) => item.value"
                  class="style-chooser"
                  label="name"
                  @input="updateForm"
                >
                </v-select>
                <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
              </ValidationProvider>

              <!--              If input is ib country select menu for EGM -->
              <ValidationProvider
                v-if="
                  (field.field === 'addr_country_ib' &&
                    whitelabel === 'EagleGlobalMarkets') ||
                  (field.field === 'addr_country_ib' &&
                    whitelabel === 'TradeCoreUK')
                "
                v-slot="{ errors }"
                :name="field.key"
                :rules="{ required: field.needed }"
              >
                <div class="text-sm leading-5 pt-4 text-gray-700">
                  <label :for="field.kind">
                    {{ field.label }}
                  </label>
                  <span v-if="field.needed" class="text-sm text-red-600 h-4">
                    *
                  </span>
                </div>
                <v-select
                  v-model="form[field.key]"
                  :clearable="false"
                  :dir="checkLanguageDirection === 'rtl' ? 'rtl' : 'auto'"
                  :filter="filterByFirstLetterIB"
                  :options="field.choices"
                  :placeholder="
                    $t('select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11')
                  "
                  :reduce="(item) => item.value"
                  class="style-chooser"
                  label="name"
                  @input="updateForm"
                >
                </v-select>
                <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
              </ValidationProvider>

              <!-- Open live account - second step - Do you have live trading experience of Forex and CFD's fileds
                   Each field is showing only when the field before has a value -->
              <div
                v-if="
                  field.key === 'live_trading_forex_cfds_experience_length' &&
                  form.live_trading_forex_cfds === 'yes'
                "
              >
                <ValidationProvider
                  v-if="field.kind === 'select'"
                  v-slot="{ errors }"
                  :name="field.key"
                  :rules="{ required: field.needed }"
                >
                  <div class="text-sm leading-5 pt-4 text-gray-700">
                    <label :for="field.kind">
                      {{ field.label }}
                    </label>
                    <span v-if="field.needed" class="text-sm text-red-600 h-4">
                      *
                    </span>
                  </div>
                  <v-select
                    v-model="form[field.key]"
                    :clearable="false"
                    :dir="checkLanguageDirection === 'rtl' ? 'rtl' : 'auto'"
                    :options="
                      field.choices.filter((item) => {
                        return item.display_name !== '';
                      })
                    "
                    :placeholder="
                      $t('select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11')
                    "
                    :reduce="(item) => item.value"
                    class="style-chooser"
                    label="display_name"
                    @input="updateForm"
                  ></v-select>
                  <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <div
                v-if="
                  field.key === 'live_trading_forex_cfds_frequency' &&
                  form.live_trading_forex_cfds_experience_length &&
                  form.live_trading_forex_cfds === 'yes'
                "
              >
                <ValidationProvider
                  v-if="field.kind === 'select'"
                  v-slot="{ errors }"
                  :name="field.key"
                  :rules="{ required: field.needed }"
                >
                  <div class="text-sm leading-5 pt-4 text-gray-700">
                    <label :for="field.kind">
                      <!-- {{
                        field.label.replace('{}', form.live_trading_forex_cfds_experience_length.replace(/_/g, ' ')
                            .replace('1 3', '1-3')
                            .replace('4 5', '4-5')
                            .replace('6 8', '6-8')
                            .replace('9 11', '9-11'))
                      }} -->
                      {{
                        checkLabel(field.label).replace(
                          /%live_trading_forex_cfds_experience_length%|{}/g,
                          form.live_trading_forex_cfds_experience_length
                            .replace(/_/g, " ")
                            .replace("1 3", "1-3")
                            .replace("4 5", "4-5")
                            .replace("6 8", "6-8")
                            .replace("9 11", "9-11")
                        )
                      }}
                    </label>
                    <span v-if="field.needed" class="text-sm text-red-600 h-4">
                      *
                    </span>
                  </div>
                  <v-select
                    v-model="form[field.key]"
                    :clearable="false"
                    :dir="checkLanguageDirection === 'rtl' ? 'rtl' : 'auto'"
                    :options="
                      field.choices.filter((item) => {
                        return item.display_name !== '';
                      })
                    "
                    :placeholder="
                      $t('select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11')
                    "
                    :reduce="(item) => item.value"
                    class="style-chooser"
                    label="display_name"
                    @input="updateForm"
                  ></v-select>
                  <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <div
                v-if="
                  field.key === 'live_trading_forex_cfds_count' &&
                  form.live_trading_forex_cfds_frequency &&
                  form.live_trading_forex_cfds === 'yes'
                "
              >
                <ValidationProvider
                  v-if="field.kind === 'select'"
                  v-slot="{ errors }"
                  :name="field.key"
                  :rules="{ required: field.needed }"
                >
                  <div class="text-sm leading-5 pt-4 text-gray-700">
                    <label :for="field.kind">
                      <!-- {{ field.label.replace('%live_trading_forex_cfds_frequency%', form.live_trading_forex_cfds_frequency.replace(/_/g, ' ')) }} -->
                      <!-- {{ checkLabel(field.label).replace('%live_trading_forex_cfds_frequency%', form.live_trading_forex_cfds_frequency.replace(/_/g, ' ')) }} -->
                      {{
                        checkLabel(field.label).replace(
                          /%live_trading_forex_cfds_frequency%|{}/g,
                          form.live_trading_forex_cfds_frequency.replace(
                            /_/g,
                            " "
                          )
                        )
                      }}
                    </label>
                    <span v-if="field.needed" class="text-sm text-red-600 h-4">
                      *
                    </span>
                  </div>
                  <v-select
                    v-model="form[field.key]"
                    :clearable="false"
                    :dir="checkLanguageDirection === 'rtl' ? 'rtl' : 'auto'"
                    :options="
                      field.choices.filter((item) => {
                        return item.display_name !== '';
                      })
                    "
                    :placeholder="
                      $t('select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11')
                    "
                    :reduce="(item) => item.value"
                    class="style-chooser"
                    label="display_name"
                    @input="updateForm"
                  ></v-select>
                  <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <!-- Open live account - second step - "Live trading experience Futures?" fileds
                   Each field is showing only when the field before has a value -->
              <div
                v-if="
                  field.key === 'live_trading_futures_length' &&
                  form.live_trading_futures_experience === 'yes'
                "
              >
                <ValidationProvider
                  v-if="field.kind === 'select'"
                  v-slot="{ errors }"
                  :name="field.key"
                  :rules="{ required: field.needed }"
                >
                  <div class="text-sm leading-5 pt-4 text-gray-700">
                    <label :for="field.kind">
                      {{ field.label }}
                    </label>
                    <span v-if="field.needed" class="text-sm text-red-600 h-4">
                      *
                    </span>
                  </div>
                  <v-select
                    v-model="form[field.key]"
                    :clearable="false"
                    :dir="checkLanguageDirection === 'rtl' ? 'rtl' : 'auto'"
                    :options="
                      field.choices.filter((item) => {
                        return item.display_name !== '';
                      })
                    "
                    :placeholder="
                      $t('select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11')
                    "
                    :reduce="(item) => item.value"
                    class="style-chooser"
                    label="display_name"
                    @input="updateForm"
                  ></v-select>
                  <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <div
                v-if="
                  field.key === 'live_trading_futures_frequency' &&
                  form.live_trading_futures_length &&
                  form.live_trading_futures_experience === 'yes'
                "
              >
                <ValidationProvider
                  v-if="field.kind === 'select'"
                  v-slot="{ errors }"
                  :name="field.key"
                  :rules="{ required: field.needed }"
                >
                  <div class="text-sm leading-5 pt-4 text-gray-700">
                    <label :for="field.kind">
                      {{
                        field.label.replace(
                          "{}",
                          form.live_trading_futures_length
                            .replace(/_/g, " ")
                            .replace("1 3", "1-3")
                            .replace("4 5", "4-5")
                            .replace("6 8", "6-8")
                            .replace("9 11", "9-11")
                        )
                      }}
                    </label>
                    <span v-if="field.needed" class="text-sm text-red-600 h-4">
                      *
                    </span>
                  </div>
                  <v-select
                    v-model="form[field.key]"
                    :clearable="false"
                    :dir="checkLanguageDirection === 'rtl' ? 'rtl' : 'auto'"
                    :options="
                      field.choices.filter((item) => {
                        return item.display_name !== '';
                      })
                    "
                    :placeholder="
                      $t('select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11')
                    "
                    :reduce="(item) => item.value"
                    class="style-chooser"
                    label="display_name"
                    @input="updateForm"
                  ></v-select>
                  <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <div
                v-if="
                  field.key === 'live_trading_futures_count' &&
                  form.live_trading_futures_frequency &&
                  form.live_trading_futures_experience === 'yes'
                "
              >
                <ValidationProvider
                  v-if="field.kind === 'select'"
                  v-slot="{ errors }"
                  :name="field.key"
                  :rules="{ required: field.needed }"
                >
                  <div class="text-sm leading-5 pt-4 text-gray-700">
                    <label :for="field.kind">
                      {{
                        field.label.replace(
                          "{}",
                          form.live_trading_futures_frequency
                        )
                      }}
                    </label>
                    <span v-if="field.needed" class="text-sm text-red-600 h-4">
                      *
                    </span>
                  </div>
                  <v-select
                    v-model="form[field.key]"
                    :clearable="false"
                    :dir="checkLanguageDirection === 'rtl' ? 'rtl' : 'auto'"
                    :options="
                      field.choices.filter((item) => {
                        return item.display_name !== '';
                      })
                    "
                    :placeholder="
                      $t('select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11')
                    "
                    :reduce="(item) => item.value"
                    class="style-chooser"
                    label="display_name"
                    @input="updateForm"
                  ></v-select>
                  <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <!-- Open live account / second step - Source of income
              we are calling sourceOfIncomeOptions computed propertie
              to remove unwanted options value based on "Employment status" field value -->
              <ValidationProvider
                v-if="
                  field.kind === 'select' && field.key === 'source_of_income'
                "
                v-slot="{ errors }"
                :name="field.key"
                :rules="{ required: field.needed }"
              >
                <div class="text-sm leading-5 pt-4 text-gray-700">
                  <label :for="field.kind">
                    {{ checkLabel(field.label) }}
                  </label>
                  <span v-if="field.needed" class="text-sm text-red-600 h-4">
                    *
                  </span>
                </div>
                <v-select
                  v-model="form[field.key]"
                  :clearable="false"
                  :dir="checkLanguageDirection === 'rtl' ? 'rtl' : 'auto'"
                  :options="
                    field.choices.filter((item) => {
                      return !sourceOfIncomeOptions.includes(item.value);
                    })
                  "
                  :placeholder="
                    $t('select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11')
                  "
                  :reduce="(item) => item.value"
                  class="style-chooser"
                  label="display_name"
                  @input="updateForm"
                ></v-select>
                <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
              </ValidationProvider>

              <!-- Open live account / First step - If user checks that he is US citizen he cannot go to step 2 -->
              <ValidationProvider
                v-if="
                  field.kind === 'select' && field.key === 'usa_tax_resident'
                "
                v-slot="{ errors }"
                :name="field.key"
                :rules="{ required: field.needed, UScitizen: true }"
              >
                <div class="text-sm leading-5 pt-4 text-gray-700">
                  <label :for="field.kind">
                    {{ field.label }}
                  </label>
                  <span v-if="field.needed" class="text-sm text-red-600 h-4">
                    *
                  </span>
                </div>
                <v-select
                  v-model="form[field.key]"
                  :clearable="false"
                  :dir="checkLanguageDirection === 'rtl' ? 'rtl' : 'auto'"
                  :options="
                    field.choices.filter((item) => {
                      return item.display_name !== '';
                    })
                  "
                  :placeholder="
                    $t('select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11')
                  "
                  :reduce="(item) => item.value"
                  class="style-chooser"
                  label="display_name"
                  @input="updateForm"
                ></v-select>
                <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
              </ValidationProvider>

              <!-- open live account - second step - liquid assets secelt input
                on ICMMU if expected deposit is larger than 30%
                and on ICMCapital if expected deposit is larger than 75%
                of the higher value of selected liquid asset
                we display an warning message below deposid field
                So we need to check this every time user changes liquid asset or inputs new deposit number
              -->
              <ValidationProvider
                v-if="
                  field.kind === 'select' &&
                  (field.key === 'approx_assets' ||
                    field.key === 'liquid_assets')
                "
                v-slot="{ errors }"
                :name="field.key"
                :rules="{ required: field.needed }"
              >
                <div class="text-sm leading-5 pt-4 text-gray-700">
                  <label v-if="field.label === 'Title'" :for="field.kind">
                    {{ $t("Title") }}
                  </label>
                  <label v-else :for="field.kind">
                    {{ field.label }}
                  </label>
                  <span v-if="field.needed" class="text-sm text-red-600 h-4">
                    *
                  </span>
                </div>
                <v-select
                  v-model="form[field.key]"
                  :clearable="false"
                  :dir="checkLanguageDirection === 'rtl' ? 'rtl' : 'auto'"
                  :options="
                    field.choices.filter((item) => {
                      return item.display_name !== '';
                    })
                  "
                  :placeholder="
                    $t('select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11')
                  "
                  :reduce="(item) => item.value"
                  class="style-chooser"
                  label="display_name"
                  @input="updateForm, checkDepositWarning()"
                ></v-select>
                <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
              </ValidationProvider>

              <!-- For TD365 and TDSouthcAfrica EagleGlobalMarkets registration - first step
              fields 'it_province' , 'au_state' , 'za_province'
              are conditional, but they dont have conditions key, so we need to write custom one
              they show up if selected addr_country is equal to the value of their addr_country field -->
              <ValidationProvider
                v-if="
                  field.kind === 'select' &&
                  (whitelabel === 'TD365' ||
                    whitelabel === 'TDSouthAfrica' ||
                    whitelabel === 'EagleGlobalMarkets' ||
                    whitelabel === 'TradeCoreUK') &&
                  (field.key === 'it_province' ||
                    field.key === 'au_state' ||
                    field.key === 'za_province') &&
                  field.addr_country === form.addr_country &&
                  !field.conditions &&
                  pageRoute.includes('accounts')
                "
                v-slot="{ errors }"
                :name="field.key"
                :rules="{ required: field.needed }"
              >
                <div class="text-sm leading-5 pt-4 text-gray-700">
                  <label v-if="field.label === 'Title'" :for="field.kind">
                    {{ $t("Title") }}
                  </label>
                  <label v-else :for="field.kind">
                    {{ field.label }}
                  </label>
                  <span v-if="field.needed" class="text-sm text-red-600 h-4">
                    *
                  </span>
                </div>
                <v-select
                  v-model="form[field.key]"
                  :clearable="false"
                  :dir="checkLanguageDirection === 'rtl' ? 'rtl' : 'auto'"
                  :options="
                    field.choices.filter((item) => {
                      return item.display_name !== '';
                    })
                  "
                  :placeholder="
                    $t('select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11')
                  "
                  :reduce="(item) => item.value"
                  class="style-chooser"
                  label="display_name"
                  @input="updateForm"
                ></v-select>
                <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
              </ValidationProvider>

              <!-- For EagleGlobalMarkets open live account from demo registration - first step
              fields 'it_province' , 'au_state' , 'za_province'
              should show up if they are sent from BE -->
              <ValidationProvider
                v-if="
                  field.kind === 'select' &&
                  (whitelabel === 'EagleGlobalMarkets' ||
                    whitelabel === 'TradeCoreUK') &&
                  (field.key === 'it_province' ||
                    field.key === 'au_state' ||
                    field.key === 'za_province') &&
                  !field.conditions &&
                  pageRoute.includes('open-live-account')
                "
                v-slot="{ errors }"
                :name="field.key"
                :rules="{ required: field.needed }"
              >
                <div class="text-sm leading-5 pt-4 text-gray-700">
                  <label v-if="field.label === 'Title'" :for="field.kind">
                    {{ $t("Title") }}
                  </label>
                  <label v-else :for="field.kind">
                    {{ field.label }}
                  </label>
                  <span v-if="field.needed" class="text-sm text-red-600 h-4">
                    *
                  </span>
                </div>
                <v-select
                  v-model="form[field.key]"
                  :clearable="false"
                  :dir="checkLanguageDirection === 'rtl' ? 'rtl' : 'auto'"
                  :options="
                    field.choices.filter((item) => {
                      return item.display_name !== '';
                    })
                  "
                  :placeholder="
                    $t('select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11')
                  "
                  :reduce="(item) => item.value"
                  class="style-chooser"
                  label="display_name"
                  @input="updateForm"
                ></v-select>
                <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
              </ValidationProvider>

              <!-- For Blackstone open live account
              fields 'it_province' , 'au_state' , 'za_province'
              are conditional, but they dont have conditions key, so we need to write custom one
              they show up if acount conutry is equal to the value of their addr_country field -->
              <ValidationProvider
                v-if="
                  field.kind === 'select' &&
                  whitelabel === 'Blackstone' &&
                  (field.key === 'it_province' ||
                    field.key === 'au_state' ||
                    field.key === 'za_province') &&
                  field.addr_country === blackstoneAccountCountry &&
                  !field.conditions
                "
                v-slot="{ errors }"
                :name="field.key"
                :rules="{ required: field.needed }"
              >
                <div class="text-sm leading-5 pt-4 text-gray-700">
                  <label v-if="field.label === 'Title'" :for="field.kind">
                    {{ $t("Title") }}
                  </label>
                  <label v-else :for="field.kind">
                    {{ field.label }}
                  </label>
                  <span v-if="field.needed" class="text-sm text-red-600 h-4">
                    *
                  </span>
                </div>
                <v-select
                  v-model="form[field.key]"
                  :clearable="false"
                  :dir="checkLanguageDirection === 'rtl' ? 'rtl' : 'auto'"
                  :options="
                    field.choices.filter((item) => {
                      return item.display_name !== '';
                    })
                  "
                  :placeholder="
                    $t('select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11')
                  "
                  :reduce="(item) => item.value"
                  class="style-chooser"
                  label="display_name"
                  @input="updateForm"
                ></v-select>
                <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
              </ValidationProvider>

              <!-- For Blackstone open live account
              fields 'gender' , 'birth_country' are conditional,
              they show up if account country is equal to the value of their addr_country field -->
              <ValidationProvider
                v-if="
                  field.kind === 'select' &&
                  whitelabel === 'Blackstone' &&
                  (field.key === 'gender' || field.key === 'birth_country') &&
                  (field.addr_country.includes(blackstoneAccountCountry) ||
                    field.addr_country === blackstoneAccountCountry) &&
                  field.conditions
                "
                v-slot="{ errors }"
                :name="field.key"
                :rules="{ required: field.needed }"
              >
                <div class="text-sm leading-5 pt-4 text-gray-700">
                  <label v-if="field.label === 'Title'" :for="field.kind">
                    {{ $t("Title") }}
                  </label>
                  <label v-else :for="field.kind">
                    {{ field.label }}
                  </label>
                  <span v-if="field.needed" class="text-sm text-red-600 h-4">
                    *
                  </span>
                </div>
                <v-select
                  v-model="form[field.key]"
                  :clearable="false"
                  :dir="checkLanguageDirection === 'rtl' ? 'rtl' : 'auto'"
                  :options="
                    field.choices.filter((item) => {
                      return item.display_name !== '';
                    })
                  "
                  :placeholder="
                    $t('select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11')
                  "
                  :reduce="(item) => item.value"
                  class="style-chooser"
                  label="display_name"
                  @input="updateForm"
                ></v-select>
                <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
              </ValidationProvider>

              <!-- EGM eagleglobalmarkets open live account from demo, field should show up on first step if received from BE -->
              <ValidationProvider
                v-if="
                  field.kind === 'select' &&
                  (whitelabel === 'EagleGlobalMarkets' ||
                    whitelabel === 'TradeCoreUK') &&
                  (field.key === 'gender' || field.key === 'birth_country') &&
                  field.conditions &&
                  pageRoute.includes('open-live-account')
                "
                v-slot="{ errors }"
                :name="field.key"
                :rules="{ required: field.needed }"
              >
                <div class="text-sm leading-5 pt-4 text-gray-700">
                  <label v-if="field.label === 'Title'" :for="field.kind">
                    {{ $t("Title") }}
                  </label>
                  <label v-else :for="field.kind">
                    {{ field.label }}
                  </label>
                  <span v-if="field.needed" class="text-sm text-red-600 h-4">
                    *
                  </span>
                </div>
                <v-select
                  v-model="form[field.key]"
                  :clearable="false"
                  :dir="checkLanguageDirection === 'rtl' ? 'rtl' : 'auto'"
                  :options="
                    field.choices.filter((item) => {
                      return item.display_name !== '';
                    })
                  "
                  :placeholder="
                    $t('select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11')
                  "
                  :reduce="(item) => item.value"
                  class="style-chooser"
                  label="display_name"
                  @input="updateForm"
                ></v-select>
                <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
              </ValidationProvider>

              <ValidationProvider
                v-if="
                  field.kind === 'select' &&
                  field.key === 'client_type' &&
                  whitelabel === 'ICMMENA'
                "
                v-slot="{ errors }"
                :name="field.key"
                :rules="{ required: field.needed }"
              >
                <!-- we put display name in po editor terms -->
                {{ translateNameString(field) }}

                <div class="text-sm leading-5 pt-4 text-gray-700">
                  <label :for="field.kind">
                    {{ field.label }}
                  </label>
                  <span v-if="field.needed" class="text-sm text-red-600 h-4">
                    *
                  </span>
                </div>
                <v-select
                  v-model="form[field.key]"
                  :clearable="false"
                  :dir="checkLanguageDirection === 'rtl' ? 'rtl' : 'auto'"
                  :options="
                    field.choices.filter((item) => {
                      return item.display_name !== '';
                    })
                  "
                  :placeholder="
                    $t('select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11')
                  "
                  :reduce="(item) => item.value"
                  class="style-chooser"
                  label="display_name"
                  @input="updateForm"
                >
                </v-select>
                <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
              </ValidationProvider>

              <!-- ICMCapital company register second step  we need to set dynamic company name -->
              <ValidationProvider
                v-if="
                  field.kind === 'select' &&
                  whitelabel === 'ICMCapital' &&
                  (field.key === 'company_other_director' ||
                    field.key === 'company_other_director_1' ||
                    field.key === 'company_other_director_2' ||
                    field.key === 'company_other_director_3' ||
                    field.key === 'company_other_director_4')
                "
                v-slot="{ errors }"
                :name="field.key"
                :rules="{ required: field.needed }"
              >
                <div class="text-sm leading-5 pt-4 text-gray-700">
                  <label :for="field.kind">
                    <span
                      v-html="
                        sanitizeCompany(
                          $t('are-there-any-other-directors-for-the-company')
                        )
                      "
                    ></span>
                  </label>
                  <span v-if="field.needed" class="text-sm text-red-600 h-4">
                    *
                  </span>
                </div>
                <v-select
                  v-model="form[field.key]"
                  :clearable="false"
                  :dir="checkLanguageDirection === 'rtl' ? 'rtl' : 'auto'"
                  :options="
                    field.choices.filter((item) => {
                      return item.display_name !== '';
                    })
                  "
                  :placeholder="
                    $t('select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11')
                  "
                  :reduce="(item) => item.value"
                  class="style-chooser"
                  label="display_name"
                  @input="updateForm"
                >
                </v-select>
                <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
              </ValidationProvider>

              <!-- If input is select menu -->

              <!-- izgleda da do sada nismo imali situaciju da je conditional polje dropdown,
              pa se desilo da je ovo polje duplirano u formi, jer cim je sistem video da polje ispunjava uslov da je dropdown,
              odmah ga je prikazao a onda kada je posle gledao i da li ima uslov, prikazao ga je jos jednom ali kao obicno input polje.
              Sad sam namestio da ne prikaze dropdown ako ima condition " && !field.conditions"
              ali sam zato u conditional polju stavio da proveri da li je dropdown ili obican input
              Ovaj red
              ((whitelabel !== 'ICMAP' && whitelabel !== 'ICMVC' && whitelabel !== 'ICMMU' && whitelabel !== 'ICMTrader') || field.key !== 'account_type') &&
              sluzi jer na open live account / seconds step, "account" type polje je conditional i negde gore iznad imamo loguku bas za to polje
              pa smo onda morali ovde da ga iskljucimo da se polje ne bi prikazivalo 2 puta
              -->
              <ValidationProvider
                v-if="
                  field.kind === 'select' &&
                  !(
                    whitelabel === 'OneTradingMarkets' &&
                    field.key === 'account_type' &&
                    pageRoute.includes('accounts')
                  ) &&
                  !(
                    whitelabel === 'Blackstone' &&
                    field.label === 'Trading currency'
                  ) &&
                  !(
                    whitelabel === 'TradeCoreUK' &&
                    field.label === 'Trading currency'
                  ) &&
                  !(
                    whitelabel === 'EagleGlobalMarkets' &&
                    field.label === 'Trading currency'
                  ) &&
                  !(
                    whitelabel === 'TD365' && field.label === 'Trading currency'
                  ) &&
                  !(
                    whitelabel === 'OneTradingMarkets' &&
                    field.label === 'Trading currency'
                  ) &&
                  ((whitelabel !== 'ICMAP' &&
                    whitelabel !== 'ICMVC' &&
                    whitelabel !== 'ICMMU' &&
                    whitelabel !== 'ICMTrader') ||
                    field.key !== 'account_type') &&
                  field.key !== 'trading_accounts' &&
                  field.field !== 'addr_country_ib' &&
                  field.field !== 'nationality_ib' &&
                  field.key !== 'live_trading_forex_cfds_experience_length' &&
                  field.key !== 'live_trading_forex_cfds_frequency' &&
                  field.key !== 'live_trading_forex_cfds_count' &&
                  field.key !== 'live_trading_futures_length' &&
                  field.key !== 'live_trading_futures_frequency' &&
                  field.key !== 'live_trading_futures_count' &&
                  field.key !== 'source_of_income' &&
                  field.key !== 'usa_tax_resident' &&
                  field.key !== 'approx_assets' &&
                  field.key !== 'liquid_assets' &&
                  field.field !== 'annual_check_country' &&
                  !(
                    whitelabel === 'Blackstone' && field.key === 'it_province'
                  ) &&
                  !(whitelabel === 'Blackstone' && field.key === 'au_state') &&
                  !(
                    whitelabel === 'Blackstone' && field.key === 'za_province'
                  ) &&
                  !(
                    whitelabel === 'TDSouthAfrica' &&
                    field.key === 'it_province'
                  ) &&
                  !(
                    whitelabel === 'TDSouthAfrica' && field.key === 'au_state'
                  ) &&
                  !(
                    whitelabel === 'TDSouthAfrica' &&
                    field.key === 'za_province'
                  ) &&
                  !(whitelabel === 'TD365' && field.key === 'it_province') &&
                  !(whitelabel === 'TD365' && field.key === 'au_state') &&
                  !(whitelabel === 'TD365' && field.key === 'za_province') &&
                  !(
                    whitelabel === 'EagleGlobalMarkets' &&
                    field.key === 'it_province'
                  ) &&
                  !(
                    whitelabel === 'EagleGlobalMarkets' &&
                    field.key === 'au_state'
                  ) &&
                  !(
                    whitelabel === 'EagleGlobalMarkets' &&
                    field.key === 'za_province'
                  ) &&
                  !(
                    whitelabel === 'EagleGlobalMarkets' &&
                    field.key === 'addr_country' &&
                    !pageRoute.includes('ib/')
                  ) &&
                  !(
                    whitelabel === 'TradeCoreUK' && field.key === 'it_province'
                  ) &&
                  !(whitelabel === 'TradeCoreUK' && field.key === 'au_state') &&
                  !(
                    whitelabel === 'TradeCoreUK' && field.key === 'za_province'
                  ) &&
                  !(
                    whitelabel === 'TradeCoreUK' &&
                    field.key === 'addr_country' &&
                    !pageRoute.includes('ib/')
                  ) &&
                  !field.conditions &&
                  !(field.key === 'client_type' && whitelabel === 'ICMMENA') &&
                  !(
                    whitelabel === 'ICMCapital' &&
                    field.key === 'company_other_director'
                  ) &&
                  !(
                    whitelabel === 'ICMCapital' &&
                    field.key === 'company_other_director_1'
                  ) &&
                  !(
                    whitelabel === 'ICMCapital' &&
                    field.key === 'company_other_director_2'
                  ) &&
                  !(
                    whitelabel === 'ICMCapital' &&
                    field.key === 'company_other_director_3'
                  ) &&
                  !(
                    whitelabel === 'ICMCapital' &&
                    field.key === 'company_other_director_4'
                  ) &&
                  field.key !== 'country_of_incorporation'
                "
                v-slot="{ errors }"
                :name="field.key"
                :rules="{ required: field.needed }"
              >
                <div class="text-sm leading-5 pt-4 text-gray-700">
                  <label v-if="field.label === 'Title'" :for="field.kind">
                    {{ $t("Title") }}
                  </label>
                  <label v-else :for="field.kind">
                    {{ checkLabel(field.label) }}
                  </label>
                  <span v-if="field.needed" class="text-sm text-red-600 h-4">
                    *
                  </span>
                </div>
                <v-select
                  v-model="form[field.key]"
                  :clearable="false"
                  :dir="checkLanguageDirection === 'rtl' ? 'rtl' : 'auto'"
                  :options="
                    field.choices.filter((item) => {
                      return item.display_name !== '';
                    })
                  "
                  :placeholder="
                    $t('select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11')
                  "
                  :reduce="(item) => item.value"
                  class="style-chooser"
                  label="display_name"
                  @input="updateForm"
                >
                </v-select>
                <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
              </ValidationProvider>

              <!--
              OTM second step client registration
              account type field will show only if trading account is selected and if selection was MT5
              -->
              <template
                v-if="
                  field.kind === 'select' &&
                  whitelabel === 'OneTradingMarkets' &&
                  field.key === 'account_type' &&
                  pageRoute.includes('accounts') &&
                  !field.conditions &&
                  pageRoute.includes('accounts') &&
                  trading_accounts[0].backend.length > 0
                "
              >
                <ValidationProvider
                  v-if="trading_accounts[0].backend === 'MT5'"
                  v-slot="{ errors }"
                  :name="field.key"
                  :rules="{ required: true }"
                >
                  <div class="text-sm leading-5 pt-4 text-gray-700">
                    <label :for="field.kind">
                      {{ checkLabel(field.label) }}
                    </label>
                    <span class="text-sm text-red-600 h-4"> * </span>
                  </div>
                  <v-select
                    v-model="form[field.key]"
                    :clearable="false"
                    :dir="checkLanguageDirection === 'rtl' ? 'rtl' : 'auto'"
                    :options="
                      field.choices.filter((item) => {
                        return item.display_name !== '';
                      })
                    "
                    :placeholder="
                      $t('select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11')
                    "
                    :reduce="(item) => item.value"
                    class="style-chooser"
                    label="display_name"
                    @input="updateForm"
                  >
                  </v-select>
                  <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
                </ValidationProvider>
              </template>

              <!-- EGM requested that we filter country results by startsWith and not with default contain -->
              <ValidationProvider
                v-if="
                  field.kind === 'select' &&
                  (whitelabel === 'EagleGlobalMarkets' ||
                    whitelabel === 'TradeCoreUK') &&
                  field.key === 'addr_country' &&
                  !pageRoute.includes('ib/')
                "
                v-slot="{ errors }"
                :name="field.key"
                :rules="{ required: field.needed }"
              >
                <div class="text-sm leading-5 pt-4 text-gray-700">
                  <label v-if="field.label === 'Title'" :for="field.kind">
                    {{ $t("Title") }}
                  </label>
                  <label v-else :for="field.kind">
                    {{ field.label }}
                  </label>
                  <span v-if="field.needed" class="text-sm text-red-600 h-4">
                    *
                  </span>
                </div>
                <v-select
                  v-model="form[field.key]"
                  :clearable="false"
                  :dir="checkLanguageDirection === 'rtl' ? 'rtl' : 'auto'"
                  :filter="filterByFirstLetter"
                  :options="
                    field.choices.filter((item) => {
                      return item.display_name !== '';
                    })
                  "
                  :placeholder="
                    $t('select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11')
                  "
                  :reduce="(item) => item.value"
                  class="style-chooser"
                  label="display_name"
                  @input="updateForm"
                >
                </v-select>
                <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
              </ValidationProvider>

              <!--              If input is checkbox-->
              <!-- for some reason on ICMEU they dont want to show risk_warning checkbox on open live account, so we inser && (whitelabel !== 'ICMEU' || field.key !== 'risk_warning')
              that will return FALSE only if whitelabel is ICMEU and key is risk_warning -->

              <ValidationProvider
                v-if="
                  field.kind === 'checkbox' &&
                  field.key !== 'swap_free_account' &&
                  (whitelabel !== 'ICMEU' || field.key !== 'risk_warning')
                "
                v-slot="{ errors }"
                :name="field.key"
                :rules="{ checkbox: field.needed }"
              >
                <div class="relative flex items-start pt-6">
                  <div class="flex items-center h-5">
                    <input
                      :id="field.key"
                      v-model="form[field.key]"
                      :required="field.needed"
                      class="form-checkbox h-4 w-4 transition duration-150 ease-in-out"
                      type="checkbox"
                      v-on:change="updateForm(form, $event.target.value)"
                    />
                  </div>
                  <div class="ml-3 text-sm leading-5 html-a register-checkbox">
                    <!-- this first name and last name logic for ICMCapital should only be applyed for open live account and not for registration -->
                    <label
                      v-if="
                        whitelabel === 'ICMMENA' &&
                        field.key === 'privacy_notice' &&
                        pageRoute.includes('accounts')
                      "
                      :for="field.key"
                      class="font-normal text-gray-700"
                      @input="$event, field.label"
                      v-html="
                        sanitizeCheckbox(
                          $t(
                            'i-have-read-and-agree-to-the-privacy-notice-icmmena'
                          )
                        )
                      "
                    >
                    </label>
                    <label
                      v-else-if="
                        whitelabel === 'ICMMENA' &&
                        field.key === 'terms_and_conditions' &&
                        pageRoute.includes('open-live-account')
                      "
                      :for="field.key"
                      class="font-normal text-gray-700"
                      @input="$event, field.label"
                      v-html="
                        sanitizeCheckbox(
                          $t(
                            'i-have-read-and-agree-to-the-terms-and-conditions-icmmena'
                          )
                        )
                      "
                    >
                    </label>
                    <label
                      v-else-if="
                        whitelabel === 'ICMCapital' &&
                        field.key === 'professional_protection_agree' &&
                        pageRoute.includes('open-live-account')
                      "
                      :for="field.key"
                      class="font-normal text-gray-700"
                      @input="$event, field.label"
                      v-html="
                        sanitizeCheckbox(
                          $t(
                            'i-have-read-and-agree-to-the-elective-professional-protection-rights'
                          )
                        )
                      "
                    >
                    </label>
                    <label
                      v-else-if="
                        whitelabel === 'ICMCapital' &&
                        field.key === 'terms_and_conditions' &&
                        pageRoute.includes('open-live-account')
                      "
                      :for="field.key"
                      class="font-normal text-gray-700"
                      @input="$event, field.label"
                      v-html="
                        sanitizeCheckbox(
                          $t(
                            'i-have-read-and-agree-to-the-icm-terms-and-condition'
                          )
                        )
                      "
                    >
                    </label>
                    <label
                      v-else-if="
                        whitelabel === 'ICMCapital' &&
                        field.key === 'company_declarations_agree' &&
                        pageRoute.includes('open-live-account')
                      "
                      :for="field.key"
                      class="font-normal text-gray-700"
                      @input="$event, field.label"
                      v-html="
                        sanitizeCheckbox(
                          $t(
                            'i-understand-and-agree-to-all-the-above-declarations'
                          )
                        )
                      "
                    >
                    </label>
                    <label
                      v-else-if="
                        whitelabel === 'ICMCapital' &&
                        field.key === 'company_resolution_passed' &&
                        pageRoute.includes('open-live-account')
                      "
                      :for="field.key"
                      class="font-normal text-gray-700"
                      @input="$event, field.label"
                      v-html="
                        sanitizeCheckbox(
                          $t(
                            'you-will-need-to-hold-a-meeting-of-the-board-of-directors-of-the-company'
                          )
                        )
                      "
                    >
                    </label>
                    <label
                      v-else-if="
                        whitelabel === 'ICMCapital' &&
                        field.key === 'company_speculative_gains' &&
                        pageRoute.includes('open-live-account')
                      "
                      :for="field.key"
                      class="font-normal text-gray-700"
                      @input="$event, field.label"
                      v-html="
                        sanitizeCheckbox(
                          $t('attempt-to-achieve-speculative-gains')
                        )
                      "
                    >
                    </label>
                    <label
                      v-else-if="
                        whitelabel === 'ICMCapital' &&
                        field.key === 'company_trade_risks' &&
                        pageRoute.includes('open-live-account')
                      "
                      :for="field.key"
                      class="font-normal text-gray-700"
                      @input="$event, field.label"
                      v-html="
                        sanitizeCheckbox(
                          $t('hedge-against-investment-or-trade-risks')
                        )
                      "
                    >
                    </label>

                    <label
                      v-else
                      :for="field.key"
                      class="font-normal text-gray-700"
                      @input="$event, field.label"
                      v-html="sanitizeCheckbox(field.label)"
                    >
                    </label>

                    <span v-if="field.needed" class="text-sm text-red-600 h-4">
                      *
                    </span>
                  </div>
                </div>
                <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
              </ValidationProvider>

              <ValidationProvider
                v-if="
                  field.key === 'swap_free_account' &&
                  (Object.values(form).includes('MY') ||
                    Object.values(form).includes('ID'))
                "
                v-slot="{ errors }"
                :name="field.key"
                :rules="{ checkbox: field.needed }"
              >
                <div class="relative flex items-start pt-6">
                  <div class="flex items-center h-5">
                    <input
                      :id="field.key"
                      v-model="form[field.key]"
                      :required="field.needed"
                      class="form-checkbox h-4 w-4 transition duration-150 ease-in-out"
                      type="checkbox"
                      v-on:change="updateForm(form, $event.target.value)"
                    />
                  </div>
                  <div class="ml-3 text-sm leading-5 html-a">
                    <label
                      :for="field.key"
                      class="font-normal text-gray-700"
                      @input="$event, field.label"
                      v-html="sanitizeCheckbox(field.label)"
                    >
                    </label>
                  </div>
                </div>
                <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
              </ValidationProvider>

              <!--  If input is radio btn-->
              <!-- we just removed the radio button with client_type condition
              because logic for that is below -->
              <ValidationProvider
                v-if="
                  field.kind === 'radio' &&
                  field.key !== 'individual_assets_confirmation' &&
                  field.key !== 'company_assets_confirmation'
                "
                v-slot="{ errors }"
                :name="field.key"
                :rules="{ required: field.needed }"
              >
                <div class="text-sm mb-4">
                  <label
                    :for="field.key"
                    class="font-normal text-gray-700 pt-4 block"
                    >{{ checkLabel(field.label) }}</label
                  >
                  <label
                    v-if="field.description"
                    :for="field.key"
                    class="font-normal text-gray-400 block"
                    >{{ field.description }}</label
                  >
                </div>
                <div
                  v-for="(choice, index) in field.choices"
                  :key="index"
                  class="flex items-center"
                >
                  <input
                    :id="choice.value + field.key"
                    v-model="form[field.key]"
                    :name="field.key"
                    :value="choice.value"
                    class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                    type="radio"
                    v-on:change="updateForm"
                  />
                  <label :for="choice.value + field.key" class="ml-3">
                    <span
                      class="inline-block text-sm leading-5 font-normal text-gray-700"
                      >{{ choice.display_name }}</span
                    >
                    <span
                      v-if="choice.description"
                      class="inline-block text-sm leading-5 font-normal text-gray-400"
                      >{{ choice.description }}</span
                    >
                    <br v-if="choice.display_name" />
                    <span
                      v-if="choice.choices_description"
                      class="inline-block text-sm leading-5 font-normal text-gray-400"
                      >{{ choice.choices_description }}</span
                    >
                  </label>
                </div>
                <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
              </ValidationProvider>

              <!-- ICMMENA live registration we check if the user_data has client_type equal to condition that is set to radio button assets field
              (individual or company)
              -->
              <ValidationProvider
                v-if="
                  field.kind === 'radio' &&
                  whitelabel === 'ICMMENA' &&
                  field.conditions &&
                  field.conditions.client_type ===
                    getUserData.extra.questionnaire_data.client_type
                "
                v-slot="{ errors }"
                :name="field.key"
                :rules="{ required: field.needed, HighAssets: true }"
              >
                <div class="text-sm mb-4">
                  <label
                    v-if="
                      getUserData.extra.questionnaire_data.client_type ===
                      'company'
                    "
                    :for="field.key"
                    class="font-normal text-gray-700 pt-4 block"
                  >
                    {{
                      $t(
                        "could-you-confirm-that-the-net-assets-of-your-corporate"
                      )
                    }}
                  </label>
                  <label
                    v-if="
                      getUserData.extra.questionnaire_data.client_type ===
                      'individual'
                    "
                    :for="field.key"
                    class="font-normal text-gray-700 pt-4 block"
                  >
                    {{ $t("could-you-confirm-that-your-net-assets") }}
                  </label>
                  <label
                    v-if="field.description"
                    :for="field.key"
                    class="font-normal text-gray-400 block"
                  >
                    {{ field.description }}
                  </label>
                </div>
                <div
                  v-for="(choice, index) in field.choices"
                  :key="index"
                  class="flex items-center"
                >
                  <input
                    :id="choice.value + field.key"
                    v-model="form[field.key]"
                    :name="field.key"
                    :value="choice.value"
                    class="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                    type="radio"
                    v-on:change="updateForm"
                  />
                  <label :for="choice.value + field.key" class="ml-3">
                    <span
                      class="inline-block text-sm leading-5 font-normal text-gray-700"
                      >{{ choice.display_name }}</span
                    >
                    <span
                      v-if="choice.description"
                      class="inline-block text-sm leading-5 font-normal text-gray-400"
                      >{{ choice.description }}</span
                    >
                    <br v-if="choice.display_name" />
                    <span
                      v-if="choice.choices_description"
                      class="inline-block text-sm leading-5 font-normal text-gray-400"
                      >{{ choice.choices_description }}</span
                    >
                  </label>
                </div>
                <span
                  v-if="
                    errors[0] ===
                    'Unfortunately we are unable to offer You a live account'
                  "
                  class="text-sm text-red-600 h-4"
                >
                  {{
                    $t(
                      "unfortunately-we-are-unable-to-offer-you-a-live-account"
                    )
                  }}
                </span>
                <span v-else class="text-sm text-red-600 h-4">{{
                  errors[0]
                }}</span>
              </ValidationProvider>

              <!-- EGM EagleGlobalMarkets registration first step Postal Code field is conditional
                  to show with every country exepct Ireland and Nigeria  -->
              <ValidationProvider
                v-if="
                  field.conditions &&
                  Object.keys(field.conditions)[0] === '!addr_country' &&
                  form.addr_country &&
                  !Object.values(field.conditions)[0].includes(
                    form.addr_country
                  ) &&
                  field.key === 'addr_zip' &&
                  (whitelabel === 'EagleGlobalMarkets' ||
                    whitelabel === 'TradeCoreUK') &&
                  (pageRoute.includes('accounts') ||
                    pageRoute.includes('mobile'))
                "
                v-slot="{ errors }"
                :name="field.key"
                :rules="{ required: field.needed }"
              >
                <label
                  :for="field.id"
                  class="inline-block text-sm font-normal leading-5 text-gray-700 pt-4"
                >
                  {{ field.label }} <br />
                </label>
                <span v-if="field.needed" class="text-sm text-red-600 h-4">
                  *
                </span>

                <div class="mt-1 rounded-md shadow-sm">
                  <input
                    :id="field.id"
                    v-model="form[field.key]"
                    :placeholder="
                      field.placeholder ? field.placeholder : field.label
                    "
                    :type="field.kind"
                    class="form-input inline-block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    @input="updateForm(form, $event.target.value)"
                  />
                </div>
                <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
              </ValidationProvider>
              <!--ICM  postcode-->
              <ValidationProvider
                v-if="field.key === 'addr_zip' && whitelabel.includes('ICM')"
                v-slot="{ errors }"
                :name="field.key"
                :rules="{ required: field.needed, twelveDigitsPostCode: true }"
              >
                <label
                  :for="field.id"
                  class="inline-block text-sm font-normal leading-5 text-gray-700 pt-4"
                >
                  {{ field.label }} <br />
                </label>
                <span v-if="field.needed" class="text-sm text-red-600 h-4">
                  *
                </span>

                <div class="mt-1 rounded-md shadow-sm">
                  <input
                    :id="field.id"
                    v-model="form[field.key]"
                    :placeholder="
                      field.placeholder ? field.placeholder : field.label
                    "
                    :type="field.kind"
                    class="form-input inline-block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    @input="updateForm(form, $event.target.value)"
                  />
                </div>
                <span class="text-sm text-red-600 h-4">{{ errors[0] }} </span>
              </ValidationProvider>

              <!-- EGM EagleGlobalMarkets open live account from demo, we show postal code field (addr_zip) but there is no field for country selection there Ireland and Nigeria
              so we leave out that  conditional check (that condition is above, for live registration) -->
              <ValidationProvider
                v-if="
                  field.conditions &&
                  Object.keys(field.conditions)[0] === '!addr_country' &&
                  field.key === 'addr_zip' &&
                  (whitelabel === 'EagleGlobalMarkets' ||
                    whitelabel === 'TradeCoreUK') &&
                  pageRoute.includes('open-live-account')
                "
                v-slot="{ errors }"
                :name="field.key"
                :rules="{ required: field.needed }"
              >
                <label
                  :for="field.id"
                  class="inline-block text-sm font-normal leading-5 text-gray-700 pt-4"
                >
                  {{ field.label }} <br />
                </label>
                <span v-if="field.needed" class="text-sm text-red-600 h-4">
                  *
                </span>

                <div class="mt-1 rounded-md shadow-sm">
                  <input
                    :id="field.id"
                    v-model="form[field.key]"
                    :placeholder="
                      field.placeholder ? field.placeholder : field.label
                    "
                    :type="field.kind"
                    class="form-input inline-block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    @input="updateForm(form, $event.target.value)"
                  />
                </div>
                <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
              </ValidationProvider>

              <!-- TD365, EagleGlobalMarkets and TDSouthAfrica registration first step , if you select Soutch africa
              ID Card field has condition of 13 caracters -->
              <ValidationProvider
                v-if="
                  field.conditions &&
                  (Object.keys(field.conditions)[0] === '!nationality'
                    ? form.nationality &&
                      !Object.values(field.conditions)[0].includes(
                        form.nationality
                      )
                    : form.hasOwnProperty(Object.keys(field.conditions)[0]) &&
                      Array.isArray(Object.values(field.conditions)[0])
                    ? Object.values(field.conditions)[0].includes(
                        form[Object.keys(field.conditions)[0]]
                      )
                    : form[Object.keys(field.conditions)[0]] ===
                      Object.values(field.conditions)[0]) &&
                  field.key === 'id_card' &&
                  (whitelabel === 'TDSouthAfrica' ||
                    whitelabel === 'TD365' ||
                    whitelabel === 'EagleGlobalMarkets' ||
                    whitelabel === 'TradeCoreUK') &&
                  pageRoute.includes('accounts')
                "
                v-slot="{ errors }"
                :name="field.key"
                :rules="{ required: field.needed, thirteenDigits: true }"
              >
                <label
                  :for="field.id"
                  class="inline-block text-sm font-normal leading-5 text-gray-700 pt-4"
                >
                  {{ field.label }}
                </label>
                <span v-if="field.needed" class="text-sm text-red-600 h-4">
                  *
                </span>

                <div class="mt-1 rounded-md shadow-sm">
                  <input
                    :id="field.id"
                    v-model="form[field.key]"
                    :placeholder="
                      field.placeholder ? field.placeholder : field.label
                    "
                    :type="field.kind"
                    class="form-input inline-block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    @input="updateForm(form, $event.target.value)"
                  />
                </div>
                <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
              </ValidationProvider>

              <!-- EagleGlobalMarkets egm open live account upgrade demo to live should show this filed on first step if it exist if you select Soutch africa before-->
              <ValidationProvider
                v-if="
                  field.conditions &&
                  field.key === 'id_card' &&
                  (whitelabel === 'EagleGlobalMarkets' ||
                    whitelabel === 'TradeCoreUK') &&
                  pageRoute.includes('open-live-account')
                "
                v-slot="{ errors }"
                :name="field.key"
                :rules="{ required: field.needed, thirteenDigits: true }"
              >
                <label
                  :for="field.id"
                  class="inline-block text-sm font-normal leading-5 text-gray-700 pt-4"
                >
                  {{ field.label }}
                </label>
                <span v-if="field.needed" class="text-sm text-red-600 h-4">
                  *
                </span>

                <div class="mt-1 rounded-md shadow-sm">
                  <input
                    :id="field.id"
                    v-model="form[field.key]"
                    :placeholder="
                      field.placeholder ? field.placeholder : field.label
                    "
                    :type="field.kind"
                    class="form-input inline-block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    @input="updateForm(form, $event.target.value)"
                  />
                </div>
                <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
              </ValidationProvider>

              <!-- Blackstone open live account , if you register with Soutch africa
              ID Card field has condition of 13 caracters -->
              <ValidationProvider
                v-if="
                  field.conditions &&
                  field.addr_country === blackstoneAccountCountry &&
                  field.key === 'id_card' &&
                  whitelabel === 'Blackstone'
                "
                v-slot="{ errors }"
                :name="field.key"
                :rules="{ required: field.needed, thirteenDigits: true }"
              >
                <label
                  :for="field.id"
                  class="inline-block text-sm font-normal leading-5 text-gray-700 pt-4"
                >
                  {{ field.label }}
                </label>
                <span v-if="field.needed" class="text-sm text-red-600 h-4">
                  *
                </span>

                <div class="mt-1 rounded-md shadow-sm">
                  <input
                    :id="field.id"
                    v-model="form[field.key]"
                    :placeholder="
                      field.placeholder ? field.placeholder : field.label
                    "
                    :type="field.kind"
                    class="form-input inline-block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    @input="updateForm(form, $event.target.value)"
                  />
                </div>
                <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
              </ValidationProvider>

              <!-- TD365, EagleGlobalMarkets and TDSouthAfrica registration first step , if you select Italy od Denmark
              cpr and tax_code field has condition of 16 caracters -->
              <ValidationProvider
                v-if="
                  field.conditions &&
                  (Object.keys(field.conditions)[0] === '!nationality'
                    ? form.nationality &&
                      !Object.values(field.conditions)[0].includes(
                        form.nationality
                      )
                    : form.hasOwnProperty(Object.keys(field.conditions)[0]) &&
                      Array.isArray(Object.values(field.conditions)[0])
                    ? Object.values(field.conditions)[0].includes(
                        form[Object.keys(field.conditions)[0]]
                      )
                    : form[Object.keys(field.conditions)[0]] ===
                      Object.values(field.conditions)[0]) &&
                  (field.key === 'cpr' || field.key === 'tax_code') &&
                  (whitelabel === 'TDSouthAfrica' ||
                    whitelabel === 'TD365' ||
                    whitelabel === 'EagleGlobalMarkets' ||
                    whitelabel === 'TradeCoreUK') &&
                  (pageRoute.includes('accounts') ||
                    pageRoute.includes('mobile'))
                "
                v-slot="{ errors }"
                :name="field.key"
                :rules="{ required: field.needed, sixteenDigits: true }"
              >
                <label
                  :for="field.id"
                  class="inline-block text-sm font-normal leading-5 text-gray-700 pt-4"
                >
                  {{ field.label }}
                </label>
                <span v-if="field.needed" class="text-sm text-red-600 h-4">
                  *
                </span>

                <div class="mt-1 rounded-md shadow-sm">
                  <input
                    :id="field.id"
                    v-model="form[field.key]"
                    :placeholder="
                      field.placeholder ? field.placeholder : field.label
                    "
                    :type="field.kind"
                    class="form-input inline-block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    @input="updateForm(form, $event.target.value)"
                  />
                </div>
                <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
              </ValidationProvider>

              <!-- EagleGlobalMarkets EGM upgrade demo to live account registration should show tax_code on first step if country is italy -->
              <ValidationProvider
                v-if="
                  field.conditions &&
                  (field.key === 'cpr' || field.key === 'tax_code') &&
                  (whitelabel === 'EagleGlobalMarkets' ||
                    whitelabel === 'TradeCoreUK') &&
                  pageRoute.includes('open-live-account')
                "
                v-slot="{ errors }"
                :name="field.key"
                :rules="{ required: field.needed, sixteenDigits: true }"
              >
                <label
                  :for="field.id"
                  class="inline-block text-sm font-normal leading-5 text-gray-700 pt-4"
                >
                  {{ field.label }}
                </label>
                <span v-if="field.needed" class="text-sm text-red-600 h-4">
                  *
                </span>

                <div class="mt-1 rounded-md shadow-sm">
                  <input
                    :id="field.id"
                    v-model="form[field.key]"
                    :placeholder="
                      field.placeholder ? field.placeholder : field.label
                    "
                    :type="field.kind"
                    class="form-input inline-block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    @input="updateForm(form, $event.target.value)"
                  />
                </div>
                <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
              </ValidationProvider>

              <!-- Blackstone open live account , if you register with Italy or Denmark
              cpr and tax_code field has condition of 16 caracters -->
              <ValidationProvider
                v-if="
                  field.conditions &&
                  field.addr_country === blackstoneAccountCountry &&
                  (field.key === 'cpr' || field.key === 'tax_code') &&
                  whitelabel === 'Blackstone'
                "
                v-slot="{ errors }"
                :name="field.key"
                :rules="{ required: field.needed, sixteenDigits: true }"
              >
                <label
                  :for="field.id"
                  class="inline-block text-sm font-normal leading-5 text-gray-700 pt-4"
                >
                  {{ field.label }}
                </label>
                <span v-if="field.needed" class="text-sm text-red-600 h-4">
                  *
                </span>

                <div class="mt-1 rounded-md shadow-sm">
                  <input
                    :id="field.id"
                    v-model="form[field.key]"
                    :placeholder="
                      field.placeholder ? field.placeholder : field.label
                    "
                    :type="field.kind"
                    class="form-input inline-block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    @input="updateForm(form, $event.target.value)"
                  />
                </div>
                <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
              </ValidationProvider>

              <!-- TD365, EagleGlobalMarkets and TDSouthAfrica registration first step , if you select Italy or Brazil
              cpf field has condition of 11 caracters -->
              <ValidationProvider
                v-if="
                  field.conditions &&
                  (Object.keys(field.conditions)[0] === '!nationality'
                    ? form.nationality &&
                      !Object.values(field.conditions)[0].includes(
                        form.nationality
                      )
                    : form.hasOwnProperty(Object.keys(field.conditions)[0]) &&
                      Array.isArray(Object.values(field.conditions)[0])
                    ? Object.values(field.conditions)[0].includes(
                        form[Object.keys(field.conditions)[0]]
                      )
                    : form[Object.keys(field.conditions)[0]] ===
                      Object.values(field.conditions)[0]) &&
                  field.key === 'cpf' &&
                  (whitelabel === 'TDSouthAfrica' ||
                    whitelabel === 'TD365' ||
                    whitelabel === 'EagleGlobalMarkets' ||
                    whitelabel === 'TradeCoreUK') &&
                  (pageRoute.includes('accounts') ||
                    pageRoute.includes('mobile'))
                "
                v-slot="{ errors }"
                :name="field.key"
                :rules="{ required: field.needed, elevenDigits: true }"
              >
                <label
                  :for="field.id"
                  class="inline-block text-sm font-normal leading-5 text-gray-700 pt-4"
                >
                  {{ field.label }}
                </label>
                <span v-if="field.needed" class="text-sm text-red-600 h-4">
                  *
                </span>

                <div class="mt-1 rounded-md shadow-sm">
                  <input
                    :id="field.id"
                    v-model="form[field.key]"
                    :placeholder="
                      field.placeholder ? field.placeholder : field.label
                    "
                    :type="field.kind"
                    class="form-input inline-block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    @input="updateForm(form, $event.target.value)"
                  />
                </div>
                <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
              </ValidationProvider>

              <!-- EagleGlobalMarkets EGM open live account first step should show this field if you previouslu selected select Italy or Brazil -->
              <ValidationProvider
                v-if="
                  field.conditions &&
                  field.key === 'cpf' &&
                  (whitelabel === 'EagleGlobalMarkets' ||
                    whitelabel === 'TradeCoreUK') &&
                  pageRoute.includes('open-live-account')
                "
                v-slot="{ errors }"
                :name="field.key"
                :rules="{ required: field.needed, elevenDigits: true }"
              >
                <label
                  :for="field.id"
                  class="inline-block text-sm font-normal leading-5 text-gray-700 pt-4"
                >
                  {{ field.label }}
                </label>
                <span v-if="field.needed" class="text-sm text-red-600 h-4">
                  *
                </span>

                <div class="mt-1 rounded-md shadow-sm">
                  <input
                    :id="field.id"
                    v-model="form[field.key]"
                    :placeholder="
                      field.placeholder ? field.placeholder : field.label
                    "
                    :type="field.kind"
                    class="form-input inline-block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    @input="updateForm(form, $event.target.value)"
                  />
                </div>
                <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
              </ValidationProvider>

              <!-- Blackstone open live account, if you register with Brazil
              cpf field has condition of 11 caracters -->
              <ValidationProvider
                v-if="
                  field.conditions &&
                  field.addr_country === blackstoneAccountCountry &&
                  field.key === 'cpf' &&
                  whitelabel === 'Blackstone'
                "
                v-slot="{ errors }"
                :name="field.key"
                :rules="{ required: field.needed, elevenDigits: true }"
              >
                <label
                  :for="field.id"
                  class="inline-block text-sm font-normal leading-5 text-gray-700 pt-4"
                >
                  {{ field.label }}
                </label>
                <span v-if="field.needed" class="text-sm text-red-600 h-4">
                  *
                </span>

                <div class="mt-1 rounded-md shadow-sm">
                  <input
                    :id="field.id"
                    v-model="form[field.key]"
                    :placeholder="
                      field.placeholder ? field.placeholder : field.label
                    "
                    :type="field.kind"
                    class="form-input inline-block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    @input="updateForm(form, $event.target.value)"
                  />
                </div>
                <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
              </ValidationProvider>

              <!-- Blackstone open live account, if you register with Spain
              nif_code and mothers_maiden_name are conditional on open live account -->
              <ValidationProvider
                v-if="
                  field.conditions &&
                  field.addr_country === blackstoneAccountCountry &&
                  (field.key === 'nif_code' ||
                    field.key === 'mothers_maiden_name') &&
                  whitelabel === 'Blackstone'
                "
                v-slot="{ errors }"
                :name="field.key"
                :rules="{ required: field.needed }"
              >
                <label
                  :for="field.id"
                  class="inline-block text-sm font-normal leading-5 text-gray-700 pt-4"
                >
                  {{ field.label }}
                </label>
                <span v-if="field.needed" class="text-sm text-red-600 h-4">
                  *
                </span>

                <div class="mt-1 rounded-md shadow-sm">
                  <input
                    :id="field.id"
                    v-model="form[field.key]"
                    :placeholder="
                      field.placeholder ? field.placeholder : field.label
                    "
                    :type="field.kind"
                    class="form-input inline-block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    @input="updateForm(form, $event.target.value)"
                  />
                </div>
                <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
              </ValidationProvider>

              <!-- EagleGlobalMarkets open live account, filed should show up on first step when upgrading demo if you previously register demo with Spain -->
              <ValidationProvider
                v-if="
                  field.conditions &&
                  (field.key === 'nif_code' ||
                    field.key === 'mothers_maiden_name') &&
                  (whitelabel === 'EagleGlobalMarkets' ||
                    whitelabel === 'TradeCoreUK') &&
                  pageRoute.includes('open-live-account')
                "
                v-slot="{ errors }"
                :name="field.key"
                :rules="{ required: field.needed }"
              >
                <label
                  :for="field.id"
                  class="inline-block text-sm font-normal leading-5 text-gray-700 pt-4"
                >
                  {{ field.label }}
                </label>
                <span v-if="field.needed" class="text-sm text-red-600 h-4">
                  *
                </span>

                <div class="mt-1 rounded-md shadow-sm">
                  <input
                    :id="field.id"
                    v-model="form[field.key]"
                    :placeholder="
                      field.placeholder ? field.placeholder : field.label
                    "
                    :type="field.kind"
                    class="form-input inline-block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    @input="updateForm(form, $event.target.value)"
                  />
                </div>
                <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
              </ValidationProvider>

              <!-- If input has condition-->

              <!-- original code, before Nationality edit -->
              <!-- <div v-if="field.conditions && form.hasOwnProperty(Object.keys(field.conditions)[0]) && form[Object.keys(field.conditions)[0]] === Object.values(field.conditions)[0]"> -->

              <!-- National passport number fields got 3 different types
              1) condition "nationality" that has only 1 value
              2) condition "nationality" that has array of values
              3) condition "!nationality" that has array of values
              We are checking if field condition is !nationality and if it is we check if nationality is not empty and if its not included in the array
              if condition is not !nationality we do the regular checking of condition that is equal to some value or to be included in array of conditional values -->

              <!--
                some fields got an array of conditions so we are checking if condition is array Array.isArray(Object.values(field.conditions)[0]) ?
                and if it is, we then check if the value of input is in that array alowed conditions
                else :
                we just check if condition value is equal to input value form[Object.keys(field.conditions)[0]] === Object.values(field.conditions)[0]
              -->
              <div
                v-if="
                  field.conditions &&
                  (Object.keys(field.conditions)[0] === '!nationality'
                    ? form.nationality &&
                      !Object.values(field.conditions)[0].includes(
                        form.nationality
                      )
                    : form.hasOwnProperty(Object.keys(field.conditions)[0]) &&
                      Array.isArray(Object.values(field.conditions)[0])
                    ? Object.values(field.conditions)[0].includes(
                        form[Object.keys(field.conditions)[0]]
                      )
                    : form[Object.keys(field.conditions)[0]] ===
                      Object.values(field.conditions)[0])
                "
              >
                <ValidationProvider
                  v-if="
                    field.fieldId === 'broker_number_of_clients' ||
                    field.fieldId === 'broker_clients_monthly_average'
                  "
                  v-slot="{ errors }"
                  :name="field.key"
                  :rules="{ required: field.needed }"
                >
                  <label
                    :for="field.id"
                    class="inline-block text-sm font-normal leading-5 text-gray-700 pt-4"
                  >
                    {{ field.label }}
                  </label>
                  <span v-if="field.needed" class="text-sm text-red-600 h-4">
                    *
                  </span>

                  <div class="mt-1 rounded-md shadow-sm">
                    <input
                      :id="field.id"
                      v-model="form[field.key]"
                      :placeholder="
                        field.placeholder ? field.placeholder : field.label
                      "
                      :type="field.kind"
                      class="form-input inline-block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      min="0"
                      @input="updateForm(form, $event.target.value)"
                    />
                  </div>
                  <span
                    v-if="errors[0] === field.key"
                    class="text-sm text-red-600 h-4"
                    >{{ $t("you-cannot-enter-a-negative-number") }}</span
                  >
                  <span v-else class="text-sm text-red-600 h-4">{{
                    errors[0]
                  }}</span>
                </ValidationProvider>

                <!-- National passport number filed has different validation min 3 and max 18 characters -->
                <ValidationProvider
                  v-else-if="
                    field.key === 'belgian_national_number' ||
                    field.key === 'bulgarian_personal_number' ||
                    field.key === 'national_identification_number_cz' ||
                    field.key === 'passport_number' ||
                    field.key === 'personal_identity_code_dk' ||
                    field.key === 'personal_identity_code_fi' ||
                    field.key === 'personal_identity_code_is' ||
                    field.key === 'estonian_personal_code' ||
                    field.key === 'es_tax_id' ||
                    field.key === 'national_insurance_number' ||
                    field.key === 'dss_investor_share' ||
                    field.key === 'personal_id_hr' ||
                    field.key === 'fiscal_code' ||
                    field.key === 'national_identity_card_number_li' ||
                    field.key === 'national_identity_card_number_nl' ||
                    field.key === 'personal_code_lt' ||
                    field.key === 'personal_code_lv' ||
                    field.key === 'national_identification_number_mt' ||
                    field.key === 'personal_id_no' ||
                    field.key === 'national_identification_number_pl' ||
                    field.key === 'tax_number_pl' ||
                    field.key === 'tax_number_pt' ||
                    field.key === 'tnational_identification_umber_ro' ||
                    field.key === 'personal_identity_number' ||
                    field.key === 'personal_identification_number_si' ||
                    field.key === 'personal_number_sk' ||
                    field.key === 'national_passport_number_1' ||
                    field.key === 'national_passport_number_2' ||
                    field.key === 'national_passport_number_notin'
                  "
                  v-slot="{ errors }"
                  :name="field.key"
                  :rules="{ regex: /^.{3,18}$/, required: field.needed }"
                >
                  <label
                    :for="field.id"
                    class="inline-block text-sm font-normal leading-5 text-gray-700 pt-4"
                  >
                    {{ field.label }}
                  </label>
                  <span v-if="field.needed" class="text-sm text-red-600 h-4">
                    *
                  </span>

                  <v-select
                    v-if="field.choices"
                    v-model="form[field.key]"
                    :clearable="false"
                    :dir="checkLanguageDirection === 'rtl' ? 'rtl' : 'auto'"
                    :options="
                      field.choices.filter((item) => {
                        return item.display_name !== '';
                      })
                    "
                    :placeholder="
                      $t('select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11')
                    "
                    :reduce="(item) => item.value"
                    class="style-chooser"
                    label="display_name"
                    @input="updateForm"
                  ></v-select>

                  <div v-else class="mt-1 rounded-md shadow-sm">
                    <input
                      :id="field.id"
                      v-model="form[field.key]"
                      :placeholder="
                        field.placeholder ? field.placeholder : field.label
                      "
                      :type="field.kind"
                      class="form-input inline-block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      @input="updateForm(form, $event.target.value)"
                    />
                  </div>
                  <span
                    v-if="errors[0] === field.key"
                    class="text-sm text-red-600 h-4"
                  >
                    {{ $t("Field-length-should-be-between-3-and-18") }}
                  </span>
                  <span v-else class="text-sm text-red-600 h-4">{{
                    errors[0]
                  }}</span>
                </ValidationProvider>

                <ValidationProvider
                  v-else-if="field.label === 'Date of Birth'"
                  v-slot="{ errors }"
                  :name="$t('please-enter-a-valid-date')"
                  :rules="{
                    required: field.needed,
                    minAgeRestriction: true,
                    maxAgeRestriction: true,
                    regex:
                      /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d\d/,
                  }"
                >
                  <div class="text-sm leading-5 pt-4">
                    <label :for="field.kind" class="font-normal text-gray-700">
                      {{ $t("date-of-birth") }}
                    </label>
                    <span v-if="field.needed" class="text-sm text-red-600 h-4">
                      *
                    </span>
                  </div>
                  <div class="mt-1 rounded-md shadow-sm relative">
                    <input
                      :id="field.id"
                      v-model="form[field.key]"
                      :placeholder="
                        field.placeholder ? field.placeholder : field.label
                      "
                      :type="field.kind"
                      class="form-input inline-block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      maxlength="10"
                      oninput="this.value=this.value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'')"
                      size="10"
                      @input="updateForm(form, $event.target.value)"
                    />
                    <input
                      v-model="form[field.key]"
                      :class="
                        $store.getters.get_lang === 'ar' ||
                        $store.getters.get_lang === 'fa'
                          ? 'left-2'
                          : 'right-2'
                      "
                      class="absolute top-2 z-20 k-datepicker"
                      type="date"
                      @input="addDate($event.target.value, field.key)"
                    />
                    <span
                      :class="
                        $store.getters.get_lang === 'ar' ||
                        $store.getters.get_lang === 'fa'
                          ? 'left-3'
                          : 'right-3'
                      "
                      class="absolute top-2"
                    >
                      <svg
                        class="w-5 h-5"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                        ></path>
                      </svg>
                    </span>
                  </div>
                  <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
                </ValidationProvider>

                <ValidationProvider
                  v-else-if="
                    field.kind !== 'radio' &&
                    !(
                      whitelabel === 'TDSouthAfrica' && field.key === 'id_card'
                    ) &&
                    !(whitelabel === 'TD365' && field.key === 'id_card') &&
                    !(whitelabel === 'Blackstone' && field.key === 'id_card') &&
                    !(
                      whitelabel === 'EagleGlobalMarkets' &&
                      field.key === 'id_card'
                    ) &&
                    !(
                      whitelabel === 'TradeCoreUK' && field.key === 'id_card'
                    ) &&
                    !(whitelabel === 'TDSouthAfrica' && field.key === 'cpr') &&
                    !(
                      whitelabel === 'EagleGlobalMarkets' && field.key === 'cpr'
                    ) &&
                    !(whitelabel === 'TradeCoreUK' && field.key === 'cpr') &&
                    !(whitelabel === 'TD365' && field.key === 'cpr') &&
                    !(whitelabel === 'Blackstone' && field.key === 'cpr') &&
                    !(
                      whitelabel === 'TDSouthAfrica' && field.key === 'tax_code'
                    ) &&
                    !(whitelabel === 'TD365' && field.key === 'tax_code') &&
                    !(
                      whitelabel === 'Blackstone' && field.key === 'tax_code'
                    ) &&
                    !(
                      whitelabel === 'EagleGlobalMarkets' &&
                      field.key === 'tax_code'
                    ) &&
                    !(
                      whitelabel === 'TradeCoreUK' && field.key === 'tax_code'
                    ) &&
                    !(whitelabel === 'TDSouthAfrica' && field.key === 'cpf') &&
                    !(whitelabel === 'TD365' && field.key === 'cpf') &&
                    !(
                      whitelabel === 'EagleGlobalMarkets' && field.key === 'cpf'
                    ) &&
                    !(whitelabel === 'TradeCoreUK' && field.key === 'cpf') &&
                    !(whitelabel === 'Blackstone' && field.key === 'cpf')
                  "
                  v-slot="{ errors }"
                  :name="field.key"
                  :rules="{ required: field.needed }"
                >
                  <label
                    :for="field.id"
                    class="inline-block text-sm font-normal leading-5 text-gray-700 pt-4"
                  >
                    {{ checkLabel(field.label) }}
                  </label>
                  <span v-if="field.needed" class="text-sm text-red-600 h-4">
                    *
                  </span>

                  <v-select
                    v-if="field.choices"
                    v-model="form[field.key]"
                    :clearable="false"
                    :dir="checkLanguageDirection === 'rtl' ? 'rtl' : 'auto'"
                    :options="
                      field.choices.filter((item) => {
                        return item.display_name !== '';
                      })
                    "
                    :placeholder="
                      $t('select-an-option-59d5d61e9e9b2dc33a0211623cc4fe11')
                    "
                    :reduce="(item) => item.value"
                    class="style-chooser"
                    label="display_name"
                    @input="updateForm"
                  ></v-select>

                  <div v-else class="mt-1 rounded-md shadow-sm">
                    <input
                      :id="field.id"
                      v-model="form[field.key]"
                      :placeholder="
                        field.placeholder
                          ? field.placeholder
                          : checkLabel(field.label)
                      "
                      :type="field.kind"
                      class="form-input inline-block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      @input="updateForm(form, $event.target.value)"
                    />
                  </div>
                  <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <!--              percentage -->

              <div v-if="field.properties">
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="field.key"
                  :rules="{
                    required: field.needed,
                    min_value: field.properties.min,
                    max_value_percentage: field.properties.max
                      ? field.properties.max
                      : Infinity,
                  }"
                >
                  <label
                    :for="field.field"
                    class="inline-block text-sm font-normal leading-5 text-gray-700 pt-4"
                  >
                    {{ field.label }}
                  </label>
                  <span v-if="field.needed" class="text-sm text-red-600 h-4">
                    *
                  </span>
                  <div class="relative mt-1 rounded-md shadow-sm">
                    <div
                      v-if="field.icon === 'icon-percentage'"
                      class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                    >
                      <span class="text-gray-500 sm:text-sm"> % </span>
                    </div>
                    <input
                      :id="field.field"
                      v-model="form[field.key]"
                      :class="field.icon === 'icon-percentage' ? 'pl-8' : ''"
                      :placeholder="
                        field.placeholder ? field.placeholder : field.label
                      "
                      :type="field.kind"
                      class="form-input inline-block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      min="0"
                      @input="updateForm(form, $event.target.value)"
                    />
                  </div>
                  <span
                    v-if="errors[0] === field.key"
                    class="text-sm text-red-600 h-4"
                    >{{ $t("you-cannot-enter-negative-percentage") }}</span
                  >
                  <span v-else class="text-sm text-red-600 h-4">{{
                    errors[0]
                  }}</span>
                </ValidationProvider>
              </div>
               <!-- Full Name Field - BR#37 - 28/11/2024 -->
              <div v-if=" whitelabel === 'ICMMU' && field.key === 'first_name'" class="pt-4">
              <ValidationProvider
                v-slot="{ errors, reset}"
                :name="full_name"
                :rules="{ required: true, alpha_spaces: true }"
              >
              <div class="flex items-center">
                <label
                  class="inline-block text-sm font-normal leading-5 text-gray-700 pt-2 full-name-label"
                >
                  Full Name
                </label>
                <span class="text-sm text-red-600 h-4"> * </span>
                <span class="text-xs text-dark-maroon full-name-input1 ">
                  Enter your Full Name as in the Proof of ID document (FirstName MiddleName LastName)
                </span>
              </div>
              
                <div class="mt-2 rounded-md shadow-sm full-name-input">
                  <input
                    v-model="form.full_name"
                    :type="field.kind"
                    class="form-input inline-block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    placeholder="Full Name"
                    @input="handleFullNameInput"
                  />
                </div>
                <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>             
              <!--  First name and Last name - BR#37 - added whitelabel !=='ICMMU' to hide fields from icmmu -->

              <!--              First name and Last name-->

              <div
                v-if="whitelabel !=='ICMMU' && (field.key === 'first_name' || field.key === 'last_name')"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="field.key"
                  :rules="{ required: field.needed, alpha_spaces: true }"
                >
                  <label
                    :for="field.id"
                    class="inline-block text-sm font-normal leading-5 text-gray-700 pt-4"
                  >
                    {{ checkLabel(field.label) }}
                  </label>
                  <span v-if="field.needed" class="text-sm text-red-600 h-4">
                    *
                  </span>
                  <!-- New highlighted instructional text -->
                  <!-- BR#22 Mbeng, changed the first name and last name -->
                  <p v-if="field.key === 'first_name'" class="text-xs text-gray-800 bg-yellow-100 p-1 mt-1 rounded">
                    <strong>Enter First Name as in Proof of ID</strong>
                  </p>
                  <p v-if="field.key === 'last_name'" class="text-xs text-gray-800 bg-yellow-100 p-1 mt-1 rounded">
                    <strong>Enter Last Name as in Proof of ID</strong>
                  <!-- BR# 22 end -->
                  </p>
                  <div class="mt-1 rounded-md shadow-sm">
                    <input
                      :id="field.id"
                      v-model="form[field.key]"
                      :placeholder="
                        field.placeholder ? field.placeholder : field.label
                      "
                      :type="field.kind"
                      class="form-input inline-block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      @input="updateForm(form, $event.target.value)"
                    />
                  </div>
                  <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <!--              addr_street-->
              <div v-if="field.key === 'addr_street'">
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="field.key"
                  :rules="{
                    required: field.needed,
                    regex:
                      whitelabel !== 'GCCBrokers' &&
                      /^[^!@#$%&*()_+=-?-.,;:'`>\</\^\[\]\\]+$/,
                  }"
                >
                  <label
                    :for="field.id"
                    class="inline-block text-sm font-normal leading-5 text-gray-700 pt-4"
                  >
                    {{ checkLabel(field.label) }}
                  </label>
                  <span v-if="field.needed" class="text-sm text-red-600 h-4">
                    *
                  </span>

                  <div class="mt-1 rounded-md shadow-sm">
                    <input
                      :id="field.id"
                      v-model="form[field.key]"
                      :placeholder="
                        field.placeholder ? field.placeholder : field.label
                      "
                      :type="field.kind"
                      class="form-input inline-block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      @input="updateForm(form, $event.target.value)"
                    />
                  </div>
                  <span
                    v-if="errors[0] === 'addr_street'"
                    class="text-sm text-red-600 h-4"
                  >
                    {{ $t("some-characters-are-not-allowed") }}
                  </span>
                  <span v-else class="text-sm text-red-600 h-4">{{
                    errors[0]
                  }}</span>
                  <span
                    v-if="
                      form['addr_street'] &&
                      form['addr_street'].length > 0 &&
                      (whitelabel === 'ICMCapital' ||
                        whitelabel === 'Promena' ||
                        whitelabel === 'ICMMENA')
                    "
                    class="text-sm text-gray-400 font-light h-4"
                  >
                    {{
                      $t(
                        "please-note-in-order-to-open-the-account-your-address-in-your-application-form-must-exactly-match-th"
                      )
                    }}
                  </span>
                </ValidationProvider>
              </div>

              <!--   > line 2 -->
              <div v-if="field.key === 'addr_line_2'">
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="field.key"
                  :rules="{
                    required: field.needed,
                    regex: /^[^!@#$%&*()_+=-?-.,;:'`\>\</\^\[\]\\]+$/,
                  }"
                >
                  <label
                    :for="field.id"
                    class="inline-block text-sm font-normal leading-5 text-gray-700 pt-4"
                  >
                    {{ checkLabel(field.label) }}
                  </label>
                  <span v-if="field.needed" class="text-sm text-red-600 h-4">
                    *
                  </span>
                  <div class="mt-1 rounded-md shadow-sm">
                    <input
                      :id="field.id"
                      v-model="form[field.key]"
                      :placeholder="
                        field.placeholder ? field.placeholder : field.label
                      "
                      :type="field.kind"
                      class="form-input inline-block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      @input="updateForm(form, $event.target.value)"
                    />
                  </div>
                  <span
                    v-if="errors[0] === 'addr_line_2'"
                    class="text-sm text-red-600 h-4"
                  >
                    {{ $t("some-characters-are-not-allowed") }}
                  </span>
                  <span v-else class="text-sm text-red-600 h-4">{{
                    errors[0]
                  }}</span>
                </ValidationProvider>
              </div>

              <!-- Open live account - second step -  "Expected Deposit in USD (Minimum 200.00)" field validation -->
              <div
                v-if="
                  field.key === 'expected_deposit' &&
                  (whitelabel === 'ICMMENA' ||
                    whitelabel === 'GCCBrokers' ||
                    whitelabel === 'TradiNext')
                "
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="field.key"
                  :rules="{ required: field.needed, min_value: 200 }"
                >
                  <label
                    :for="field.id"
                    class="inline-block text-sm font-normal leading-5 text-gray-700 pt-4"
                  >
                    {{ checkLabel(field.label) }}
                  </label>
                  <span v-if="field.needed" class="text-sm text-red-600 h-4">
                    *
                  </span>
                  <div class="mt-1 rounded-md shadow-sm">
                    <input
                      :id="field.id"
                      v-model="form[field.key]"
                      :placeholder="
                        field.placeholder ? field.placeholder : field.label
                      "
                      :type="field.kind"
                      class="form-input inline-block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      @input="
                        updateForm(form, $event.target.value),
                          checkLiquidAssets()
                      "
                    />
                  </div>
                  <span
                    v-if="errors[0] === 'expected_deposit'"
                    class="text-sm text-red-600 h-4"
                    >{{ $t("minimum-deposit-is-200-usd") }}</span
                  >
                  <span v-else class="text-sm text-red-600 h-4">{{
                    errors[0]
                  }}</span>
                  <span
                    v-if="showDepositWarning && whitelabel === 'ICMMENA'"
                    class="text-sm text-red-600 h-4 html-a"
                    v-html="
                      sanitizeText(
                        $t(
                          'warning-engaging-in-cfds-or-spot-fx-carries-a-high-risk-to-your-cap-icmmena'
                        )
                      )
                    "
                  />
                  <span
                    v-if="showDepositWarning && whitelabel !== 'ICMMENA'"
                    class="text-sm text-red-600 h-4 html-a"
                    v-html="
                      sanitizeText(
                        $t(
                          'warning-engaging-in-cfds-or-spot-fx-carries-a-high-risk-to-your-cap-8be2a602c2da5cdf8442deeb3e640bb8'
                        )
                      )
                    "
                  />
                </ValidationProvider>
              </div>

              <!-- Open live account - second step -  "Expected Deposit in USD (Minimum 2000.00)" field validation -->
              <div
                v-if="
                  field.key === 'expected_deposit' &&
                  whitelabel === 'ICMCapital'
                "
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="field.key"
                  :rules="{ required: field.needed, min_value: 2000 }"
                >
                  <label
                    :for="field.id"
                    class="inline-block text-sm font-normal leading-5 text-gray-700 pt-4"
                  >
                    {{ $t("expected-deposit-in-usd-2000") }}
                  </label>
                  <span v-if="field.needed" class="text-sm text-red-600 h-4">
                    *
                  </span>
                  <div class="mt-1 rounded-md shadow-sm">
                    <input
                      :id="field.id"
                      v-model="form[field.key]"
                      :placeholder="'Expected Deposit in USD (Minimum 2000.00)'"
                      :type="field.kind"
                      class="form-input inline-block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      @input="
                        updateForm(form, $event.target.value),
                          checkLiquidAssets()
                      "
                    />
                  </div>
                  <span
                    v-if="errors[0] === 'expected_deposit'"
                    class="text-sm text-red-600 h-4"
                    >{{ $t("minimum-deposit-is-2000-usd") }}</span
                  >
                  <span v-else class="text-sm text-red-600 h-4">{{
                    errors[0]
                  }}</span>
                  <span
                    v-if="showDepositWarning"
                    class="text-sm text-red-600 h-4 html-a"
                    v-html="
                      sanitizeText(
                        $t(
                          'warning-engaging-in-cfds-or-spot-fx-carries-a-high-risk-to-your-cap-8be2a602c2da5cdf8442deeb3e640bb8'
                        )
                      )
                    "
                  />
                </ValidationProvider>
              </div>

              <!-- Expected deposit without minimum value condition (cannot be negative numbers) -->
              <div
                v-if="
                  field.key === 'expected_deposit' &&
                  whitelabel !== 'ICMCapital' &&
                  whitelabel !== 'ICMMENA' &&
                  whitelabel !== 'GCCBrokers' &&
                  whitelabel !== 'TradiNext'
                "
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="field.key"
                  :rules="{ required: field.needed, min_value: 0 }"
                >
                  <label
                    :for="field.id"
                    class="inline-block text-sm font-normal leading-5 text-gray-700 pt-4"
                  >
                    {{ field.label }}
                  </label>
                  <span v-if="field.needed" class="text-sm text-red-600 h-4">
                    *
                  </span>
                  <div class="mt-1 rounded-md shadow-sm">
                    <input
                      :id="field.id"
                      v-model="form[field.key]"
                      :placeholder="
                        field.placeholder ? field.placeholder : field.label
                      "
                      :type="field.kind"
                      class="form-input inline-block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      @input="
                        updateForm(form, $event.target.value),
                          checkLiquidAssets()
                      "
                    />
                  </div>
                  <span
                    v-if="errors[0] === 'expected_deposit'"
                    class="text-sm text-red-600 h-4"
                    >{{ $t("sorry-you-cannot-deposit-negative-amount") }}</span
                  >
                  <span v-else class="text-sm text-red-600 h-4">{{
                    errors[0]
                  }}</span>
                  <span
                    v-if="showDepositWarning"
                    class="text-sm text-red-600 h-4 html-a"
                    v-html="
                      $t(
                        'warning-engaging-in-cfds-or-spot-fx-carries-a-high-risk-to-your-cap-8be2a602c2da5cdf8442deeb3e640bb8'
                      )
                    "
                  />
                </ValidationProvider>
              </div>

              <!-- ICMMENA wanted to show this field with no placeholder text -->
              <div
                v-if="
                  field.key === 'other_experience' &&
                  (whitelabel === 'GCCBrokers' || whitelabel === 'TradiNext')
                "
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="field.key"
                  :rules="{ required: field.needed }"
                >
                  <label
                    :for="field.id"
                    class="inline-block text-sm font-normal leading-5 text-gray-700 pt-4"
                  >
                    {{ checkLabel(field.label) }}
                  </label>
                  <span v-if="field.needed" class="text-sm text-red-600 h-4">
                    *
                  </span>
                  <div class="mt-1 rounded-md shadow-sm">
                    <input
                      :id="field.id"
                      v-model="form[field.key]"
                      :type="field.kind"
                      class="form-input inline-block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      @input="updateForm(form, $event.target.value)"
                    />
                  </div>
                  <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <!--              Other inputs-->
              <div
                v-if="
                  field.kind !== 'select' &&
                  field.kind !== 'checkbox' &&
                  field.key !== 'password' &&
                  field.key !== 'password_confirmation' &&
                  field.kind !== 'date_of_birth' &&
                  field.key !== 'telephone' &&
                  field.key !== 'first_name' &&
                  field.key !== 'last_name' &&
                  !(field.key === 'addr_zip' && whitelabel.includes('ICM')) &&
                  field.key !== 'addr_street' &&
                  field.key !== 'addr_line_2' &&
                  field.key !== 'trading_accounts' &&
                  field.kind !== 'radio' &&
                  !field.conditions &&
                  field.kind !== 'title' &&
                  field.key !== 'expected_deposit' &&
                  !field.properties &&
                  !(
                    field.key === 'other_experience' &&
                    whitelabel === 'GCCBrokers'
                  ) &&
                  !(
                    field.key === 'other_experience' &&
                    whitelabel === 'TradiNext'
                  ) &&
                  field.key !== 'company_resolution_date' &&
                  field.key !== 'company_director_date_of_birth' &&
                  field.key !== 'company_date'
                "
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  :name="field.key"
                  :rules="{ required: field.needed }"
                >
                  <label
                    :for="field.id"
                    class="inline-block text-sm font-normal leading-5 text-gray-700 pt-4"
                  >
                    {{ checkLabel(field.label) }}
                  </label>
                  <span
                    v-if="field.label === 'Tax ID (VAT ID)'"
                    class="text-sm text-red-600 h-4"
                  >
                    *
                  </span>
                  <span v-if="field.needed" class="text-sm text-red-600 h-4">
                    *
                  </span>
                  <div class="mt-1 rounded-md shadow-sm">
                    <input
                      :id="field.id"
                      v-model="form[field.key]"
                      :placeholder="
                        field.placeholder
                          ? field.placeholder
                          : checkLabel(field.label)
                      "
                      :type="field.kind"
                      class="form-input inline-block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      @input="updateForm(form, $event.target.value)"
                    />
                  </div>
                  <span class="text-sm text-red-600 h-4">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <div v-if="field.kind === 'title'" class="mb-6 mt-10">
                {{ field.subtitle }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--      <vue-recaptcha ref="recaptcha"-->
      <!--                     v-if="showRecaptcha"-->
      <!--                     class="g-recaptcha mt-4"-->
      <!--                     @verify="onVerify"-->
      <!--                     :sitekey="key">-->
      <!--      </vue-recaptcha>-->
      <div class="mt-4 pt-5">
        <div class="flex justify-end">
          <span class="ml-3 inline-flex rounded-md shadow-sm">
            <button
              :disabled="isDisabled"
              class="bg-indigo-600 text-white inline-flex justify-center py-2 px-8 border border-transparent text-sm leading-5 font-normal rounded-md focus:outline-none transition duration-150 ease-in-out"
              type="button"
              @click="testFields"
            >
              <span v-if="formType === 'annual-check'"> Submit </span>
              <span
                v-else-if="
                  ($store.getters.show_next_step ===
                    registrationNumberOfSteps ||
                    registrationNumberOfSteps === 0) &&
                  whitelabel === 'GCCBrokers'
                "
              >
                {{ $t("create-account") }}
              </span>
              <span
                v-else-if="
                  $store.getters.show_next_step === registrationNumberOfSteps ||
                  registrationNumberOfSteps === 0
                "
              >
                {{ whitelabel === "VCGMarkets" ? $t("Proceed") : $t("Finish") }}
              </span>
              <span v-else>
                {{ $t("Next") }}
              </span>
            </button>
          </span>
        </div>
        <div v-if="formType === 'annual-check'" class="flex justify-end">
          By submitting you confirm that these details are correct.
        </div>

        <successful-modal v-if="showSuccessfulModal"></successful-modal>
        <td365-unsuitable-modal
          v-if="showTd365UnsuitableModal"
        ></td365-unsuitable-modal>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import SuccessfulModal from "@/views/lead/SuccessfulModal";
import PoliticallyExposedModal from "@/views/trading-accounts/PoliticallyExposedModal";
import Td365UnsuitableModal from "@/views/register/Td365UnsuitableModal";
import VueRecaptcha from "vue-recaptcha";
import { passwordPattern } from "@/views/choices";
import { mapGetters } from "vuex"; // this is imported so we can use "return i18n.t(...." in methods
import Vue from "vue";
import VueI18Next from "@panter/vue-i18next";
import i18next from "i18next";

Vue.use(VueI18Next);
const i18n = new VueI18Next(i18next);

export default {
  name: "Form",
  components: {
    VuePhoneNumberInput,
    SuccessfulModal,
    PoliticallyExposedModal,
    Td365UnsuitableModal,
    VueRecaptcha,
  },
  data() {
    return {
      pageRoute: this.$route.path,
      form: { 
          full_name: "",
          //first_name: "", // First name for extraction #BR#37
          //last_name: "",  // Last name for extraction #BR#37
          },
      trading_accounts: [
        {
          account_type: "",
          backend: "",
          currency: "",
        },
      ],
      phone: "",
      stage: "",
      showRequired: false,
      showSuccessfulModal: false,
      showPoliticallyExposed: false,
      showTd365UnsuitableModal: false,
      isDisabled: false,
      key: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY,
      passwordPattern: passwordPattern(),
      whitelabel: process.env.VUE_APP_WHITELABEL,
      languageDirection: "",
      phoneValid: false,
      showDepositWarning: false,
      registrationNumberOfSteps: "",
      blackstoneAccountCountry: "",
      showRequiredMessage: false,
      formPass: false,
      showTheForm: true,
      showLoadingSpinner: false,
    };
  },
  props: {
    demoForm: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
    demoCompany: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
    openLiveForm: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
    liveForm: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
    step: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    tradingPlatforms: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    forms: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },
    formType: {
      type: String,
      required: false,
      default: () => {
        return "";
      },
    },
    id: {
      type: Number,
      required: true,
      default: () => {
        return 0;
      },
    },
    numberOfSteps: {
      type: Number,
      required: false,
      default: () => {
        return 0;
      },
    },
    classes: {
      type: String,
      required: false,
      default: () => {
        return "";
      },
    },
    egmDemo: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
  },
  computed: {
    ...mapGetters(["get_profile", "get_profile_options"]),
    // Open live account / second step - Source of income
    // we are changing options value for "Source of income" based on "Employment status" field value
    // this is does not apply for ICMVC , ICMMU, ICMAP and ICMTrader
    sourceOfIncomeOptions() {
      if (
        process.env.VUE_APP_WHITELABEL === "ICMVC" ||
        process.env.VUE_APP_WHITELABEL === "ICMAP" ||
        process.env.VUE_APP_WHITELABEL === "ICMMU" ||
        process.env.VUE_APP_WHITELABEL === "ICMTrader" ||
        process.env.VUE_APP_WHITELABEL === "OrbitInvest"
      ) {
        return [];
      } else {
        if (
          this.form.employment_status === "employed" ||
          this.form.employment_status === "self_employed"
        ) {
          return ["private_pension", "state_pension"];
        } else if (this.form.employment_status === "retired") {
          return ["salary"];
        } else if (this.form.employment_status === "student") {
          return ["salary", "private_pension", "state_pension"];
        } else if (this.form.employment_status === "unemployed") {
          return [
            "salary",
            "savings_salary",
            "private_pension",
            "state_pension",
          ];
        } else {
          return [];
        }
      }
    },
    //BR#37
    filteredFields() {
      return this.formFields.filter(field => field.key !== "full_name");
    },
    getUserData() {
      return this.$store.getters.get_user_data;
    },
    get_demo_trading_platforms() {
      return this.$store.getters.get_demo_trading_platforms;
    },
    get_trading_platforms() {
      return this.$store.getters.get_trading_platforms;
    },
    // on select forms, we need to set dir = 'rtl' instead of default dir='auto'
    // so non translated text would show the corect way on arab and persian pages
    checkLanguageDirection() {
      this.languageDirection = this.$store.getters.get_lang;
      if (this.lang === "ar" || this.lang === "fa") {
        return document.getElementById("body").dir;
      } else {
        return document.getElementById("body").dir;
      }
    },

    // validate emaildomain spelling typo yahoo, gmail, hotmail
    //BR#21 Mbeng Atemson 28/08/2024  autoCorrect Email Domain 
  correctedEmail() {
    const domainCorrections = {
      'yaho.com': 'yahoo.com',
      'yahooo.com': 'yahoo.com',
      'yahoo.co': 'yahoo.com',
      'yahoo.cm': 'yahoo.com',
      'gmail.co': 'gmail.com',
      'gmai.com': 'gmail.com',
      'gmail.cm': 'gmail.com',
      'hotmai.com': 'hotmail.com',
      'hotmail.co': 'hotmail.com',
      'hotmeil.com': 'hotmail.com',
      'gmail.comm': 'gmail.com',
      'yahoo.comm': 'yahoo.com',
      'hotmail.comm': 'hotmail.com',
    };

    const email = this.form.email ? this.form.email.trim() : '';
    const emailParts = email.split('@');

    if (emailParts.length === 2) {
      let emailDomain = emailParts[1].toLowerCase();

      if (domainCorrections[emailDomain]) {
        return `${emailParts[0]}@${domainCorrections[emailDomain]}`;
      }
    }
    return email;
  }
  },

   // validate emaildomain spelling typo yahoo, gmail, hotmail
  // BR#21 Mbeng Atemson 28/08/2024 
  watch: {
  'form.email': function(newValue) {
    this.form.email = this.correctedEmail;
  }
},

  created() {
    this.stage = process.env.VUE_APP_API_URL.includes("dev.")
      ? "dev"
      : process.env.VUE_APP_API_URL.includes("stage.")
      ? "stage"
      : "production";
    this.registrationNumberOfSteps = this.numberOfSteps;

    if (this.whitelabel === "Blackstone" && this.get_profile.address) {
      this.blackstoneAccountCountry =
        this.get_profile.address.addr_country_iso_code;
    }

    if (this.formType === "annual-check") {
      this.form.addr_street = this.get_profile.address.addr_street;
      this.form.addr_line_2 = this.get_profile.address.addr_line_2;
      this.form.addr_city = this.get_profile.address.addr_city;
      this.form.addr_zip = this.get_profile.address.addr_zip;
      this.form.telephone = this.get_profile.address.telephone;
    }
  },
  mounted() {
    if (localStorage.getItem("storedData")) {
      let storedData = JSON.parse(localStorage.getItem("storedData"));
      this.form = storedData;

      if (storedData.hasOwnProperty("telephone")) {
        this.phone = storedData["telephone"];
      }
    }

    // since td365 has 4 steps of registration (is Australia is selected)
    // we need to manualy remove the fourth step when form reached third step and addr country is not australia
    // we had locig for this on line 2700 but it worked only after we click the "next" button
    // but button needs have a text of "finish" instead of "next" on last step, so we moved the logic when the form is mounted in the DOM
    if (
      this.whitelabel === "TD365" &&
      this.liveForm &&
      this.$store.getters.show_next_step === 2 &&
      this.form.addr_country !== "AU"
    ) {
      this.registrationNumberOfSteps = this.registrationNumberOfSteps - 1;
    }
  },
  methods: {
    async getCountryName() {
      const url = "https://ipapi.co/json/";
      const response = await fetch(url);
      const data = await response.json();
      return data?.country_code ? data.country_code : "";
    },
    // extract first_name and last_name from full_name  BR#37 28/11/2024
    handleFullNameInput() {
    const nameParts = this.form.full_name.trim().split(" ");
    this.form.first_name = nameParts[0] || ""; // First part of the name
    this.form.last_name = nameParts.slice(1).join(" ") || ""; // Remaining as last name
  },
    // sanitize rules are at main.js defaultOptions
    sanitizeCheckbox(fieldLabel) {
      if (
        (this.whitelabel === "ICMCapital" ||
          this.whitelabel === "ICMMENA" ||
          this.whitelabel === "GCCBrokers" ||
          this.whitelabel === "TradiNext") &&
        this.pageRoute === "/open-live-account"
      ) {
        return this.$sanitize(
          fieldLabel
            .replace("%first_name%", this.getUserData.first_name)
            .replace("%last_name%", this.getUserData.last_name)
            .replace("%company_name%", this.get_profile.company_name)
            .replace("{company_name}", this.get_profile.company_name)
            .replace(
              "{}",
              this.getUserData.first_name + " " + this.getUserData.last_name
            )
            .replace("undefined", "")
            .replace("undefined,", "")
        );
      } else if (
        this.whitelabel === "ICMTrader" &&
        this.pageRoute === "/open-live-account"
      ) {
        return this.$sanitize(
          fieldLabel
            .replace("Fx Gravity", "ICM Trader")
            .replace("FX Gravity", "ICM Trader")
            .replace("FX Gravity", "ICM Trader")
            .replace(
              "http://fxgravity.co.uk/terms-conditions/",
              "https://www.icmtrader.com/"
            )
            .replace("fxgravity.co.uk", "www.icmtrader.com")
            .replace("FX Gravity", "ICM Trader")
            .replace("ICM Capital", "ICM Trader")
        );
      } else {
        if (this.whitelabel !== "OneTradingMarkets") {
          return this.$sanitize(
            fieldLabel
              .replace("%first_name%", this.form.first_name)
              .replace("%last_name%", this.form.last_name)
              .replace("{}", this.form.first_name + " " + this.form.last_name)
              .replace("undefined", "")
              .replace("undefined,", "")
          );
        } else {
          return this.$sanitize(fieldLabel);
        }
      }
    },
    sanitizeSwapFreeAccount(fieldLabel) {
      return this.$sanitize(
        fieldLabel
          .replace("%first_name%", this.form.first_name)
          .replace("%last_name%", this.form.last_name)
          .replace("{}", this.form.first_name + " " + this.form.last_name)
      );
    },
    sanitizeText(text) {
      if (
        (this.whitelabel === "ICMCapital" &&
          this.pageRoute === "/open-live-account" &&
          this.getUserData.extra?.client_type === "company") ||
        (this.whitelabel === "GCCBrokers" &&
          this.getUserData.extra?.client_type === "company")
      ) {
        return this.$sanitize(
          text
            .replace("%first_name%", this.get_profile.first_name)
            .replace("%last_name%", this.get_profile.last_name)
            .replace("{first_name}", this.get_profile.first_name)
            .replace("{last_name}", this.get_profile.last_name)
            .replace("{company_name}", this.get_profile.company_name)
            .replace("%company_name%", this.get_profile.company_name)
            .replace(
              "{company_resolution_date}",
              this.form.company_resolution_date
            )
        );
      } else if (
        text === "So that we can verify your identity for regulatory reasons."
      ) {
        return i18n.t(
          "so-that-we-can-verify-your-identity-for-regulatory-reasons"
        );
      } else if (
        text ===
        "Please provide these details to help us asses your suitability for trading with us."
      ) {
        return i18n.t(
          "please-provide-these-details-to-help-us-asses-your-suitability-for-trading-with-us"
        );
      } else if (
        text ===
        "Please answer all sections accurately in order to help us review your application."
      ) {
        return i18n.t(
          "please-answer-all-sections-accurately-in-order-to-help-us-review-your-application"
        );
      } else if (
        text ===
        "Have you traded in any of the following in the past three years?"
      ) {
        return i18n.t(
          "have-you-traded-in-any-of-the-following-in-the-past-three-years"
        );
      } else if (text === "Please answer all questions accurately.") {
        return i18n.t("please-answer-all-questions-accurately");
      } else {
        return this.$sanitize(text);
      }
    },
    sanitizeCompany(text) {
      return this.$sanitize(
        text.replace(
          "%company_name%" || "{company_name}",
          this.get_profile.company_name
        )
      );
    },
    checkTitle(title) {
      switch (title) {
        case "Create your account":
          return i18n.t("create-your-account");
          break;
        case "Trading Experience":
          return i18n.t("trading-experience");
          break;
        case "Employment information":
          return i18n.t("employment-information");
          break;
        case "Financial information":
          if (this.whitelabel === "VCGMarkets") {
            return "Financial information";
          }

          return i18n.t("financial-information");
          break;
        case "Company Details":
          return i18n.t("company-details");
          break;
        case "Director Lists":
          return i18n.t("director-lists");
          break;
        case "Company Bank Account Details":
          return i18n.t("company-bank-account-details");
          break;
        case "Investment Details":
          return i18n.t("investment-details");
          break;
        case "Investment Objective":
          return i18n.t("investment-objective");
          break;
        case "Elective Professional qualitative assessment":
          return i18n.t("elective-professional-qualitative-assessment");
          break;
        case "Personal Details":
          return i18n.t("personal-details");
          break;
        case "Address":
          return i18n.t("Address");
          break;
        case "Financial Details":
          return i18n.t("financial-details");
          break;
        case "Other Relevant Experience":
          return i18n.t("other-relevant-experience");
          break;
        case "Declarations":
          return i18n.t("declarations");
          break;
        case "Trading account details":
          return i18n.t("trading-account-details");
          break;
        case "Marketing Information":
          return i18n.t("marketing-information");
          break;
        default:
          return title;
          break;
      }
    },
    checkLabel(label) {
      switch (label) {
        case "Source of Income / Wealth:":
          return i18n.t("source-of-income-wealth");
          break;
        case "Total Estimated Net Worth ($)?":
          return i18n.t("total-estimated-net-worth");
          break;
        case "Total Estimated Annual Income ($)?":
          return i18n.t("total-estimated-annual-income");
          break;
        case "Your Employment Status":
          return i18n.t("your-employment-status");
          break;
        case "Forex, CFDs and other instruments":
          return i18n.t("forex-cfds-and-other-instruments");
          break;
        case "Derivative products are suitable as part of my investment objectives and attitude towards risk and I am able to assess the risk involved in trading them, including the possibility that I may lose all of my capital":
          return i18n.t(
            "derivative-products-are-suitable-as-part-of-my-investment"
          );
          break;
        case "I have previous professional qualifications and/or work experience in the financial services industry":
          return i18n.t(
            "i-have-previous-professional-qualifications-and-or-work-experience-in-the-financial-services-industry"
          );
          break;
        case "Trading platform":
          return i18n.t("trading-platform");
          break;
        case 'I have read, understood and accepted the <a href="https://onetradingmarkets.com/privacy-policy-2/">Privacy Policy</a>, Risk Disclosures and <a href="https://onetradingmarkets.com/terms-conditions/">Terms & Conditions</a>':
          return i18n.t(
            "i-have-read-understood-and-accepted-the-privacy-policy-risk-disclosures-and-terms-and-conditions-links"
          );
          break;
        case "Elective Professional qualitative assessment":
          return i18n.t("elective-professional-qualitative-assessment");
          break;
        case "Director Lists":
          return i18n.t("director-lists");
          break;
        case "Company Details":
          return i18n.t("company-details");
          break;
        case "Company Business (mailing) Address":
          return i18n.t("company-business-mailing-address");
          break;
        case "Company Registered Address":
          return i18n.t("company-registered-address");
          break;
        case "Website":
          return i18n.t("website");
          break;
        case "Name of Director":
          return i18n.t("name-of-director");
          break;
        case "Name of Director 2":
          return i18n.t("name-of-director-2");
          break;
        case "Name of Director 3":
          return i18n.t("name-of-director-3");
          break;
        case "Name of Director 4":
          return i18n.t("name-of-director-4");
          break;
        case "Director Address":
          return i18n.t("director-address");
          break;
        case "Name of Bank":
          return i18n.t("name-of-bank");
          break;
        case "Address of Bank":
          return i18n.t("address-of-bank");
          break;
        case "Account name":
          return i18n.t("account-name");
          break;
        case "Account number":
          return i18n.t("account-number");
          break;
        case "Bank Sort Code":
          return i18n.t("bank-sort-code");
          break;
        case "Is there any other Directorships within the last 5 Years?":
          return i18n.t(
            "is-there-any-other-directorships-within-the-last-5-years"
          );
          break;
        case "Approximate funds available for the Company to trade, USD":
          return i18n.t(
            "approximate-funds-available-for-the-company-to-trade-usd"
          );
          break;
        case "I have carried out transactions, in significant size, on the relevant market(s), at an average frequency of 10 per quarter over the previous 4 quarters.":
          return i18n.t("i-have-carried-out-transactions-in-significant-size");
          break;
        case "My financial instrument portfolio, defined as including cash deposits and financial instruments, exceeds EUR 500,000":
          return i18n.t(
            "my-financial-instrument-portfolio-defined-as-including-cash-deposits"
          );
          break;
        case "I have worked in the financial sector for at least one year in a professional position which requires knowledge of the transactions envisaged.":
          return i18n.t(
            "i-have-worked-in-the-financial-sector-for-at-least-one-year-in-a-professional-position"
          );
          break;
        case "First name":
          return i18n.t("first-name-20db0bfeecd8fe60533206a2b5e9891a");
          break;
        case "Last name":
          return i18n.t("last-name-8d3f5eff9c40ee315d452392bed5309b");
          break;
        case "Password":
          return i18n.t("Password");
          break;
        case "Please choose a password":
          return i18n.t("Password");
          break;
        case "Email":
          return i18n.t("Email");
          break;
        case "Phone number":
          return i18n.t("phone-number-1f8261d17452a959e013666c5df45e07");
          break;
        case "Country":
          return i18n.t("Country");
          break;
        case "Address line 1":
          return i18n.t("address-line-1");
          break;
        case "Address line 2":
          return i18n.t("address-line-2");
          break;
        case "City or Town":
          return i18n.t("city-or-town");
          break;
        case "FATCA: Are you a citizen or resident of the United States?":
          return i18n.t(
            "fatca-are-you-a-citizen-or-resident-of-the-united-states"
          );
          break;
        case "Approximate Annual Income":
          return i18n.t("approximate-annual-income");
          break;
        case "Profession / Status":
          return i18n.t("profession-status");
          break;
        case "Purpose":
          return i18n.t("Purpose");
          break;
        case "Shares":
          return i18n.t("Shares");
          break;
        case "Forex":
          return i18n.t("Forex");
          break;
        case "Have you attended a relevant training course?":
          return i18n.t("have-you-attended-a-relevant-training-course");
          break;
        case "Have you had any experience working in the financial sector?":
          return i18n.t(
            "have-you-had-any-experience-working-in-the-financial-sector"
          );
          break;
        case "Source of Funds":
          return i18n.t("source-of-funds");
          break;
        case "Do you have any other relevant experience?":
          return i18n.t("do-you-have-any-other-relevant-experience");
          break;
        case "Nationality":
          return i18n.t("Nationality");
          break;
        case "Employment Status":
          return i18n.t("employment-status");
          break;
        case "Purpose of this Account":
          return i18n.t("purpose-of-this-account");
          break;
        case "Source of Funds":
          return i18n.t("Source-of-Funds");
          break;
        case "Do you understand the nature and risks of trading?":
          return i18n.t("do-you-understand-the-nature-and-risks-of-trading");
          break;
        case "Do you have any prior experience working in the financial sector?":
          return i18n.t(
            "do-you-have-any-prior-experience-working-in-the-financial-sector"
          );
          break;
        case "Have you attended any relevant training course or certification?":
          return i18n.t(
            "have-you-attended-any-relevant-training-course-or-certification"
          );
          break;
        case "Trading currency":
          return i18n.t("trading-currency");
          break;
        case "Account type":
          return i18n.t("account-type");
          break;
        case "Expected Deposit in USD":
          return i18n.t("expected-deposit-in-usd");
          break;
        case "How did you find us?":
          return i18n.t("how-did-you-find-us");
          break;
        case "Business / Employer Name":
          return i18n.t("business-employer-name");
          break;
        case "Job Title":
          return i18n.t("job-title");
          break;
        case "3. During the last %live_trading_forex_cfds_experience_length%, how frequently have you traded?":
          return i18n.t("during-the-last-how-frequently-have-you-traded");
          break;
        case "4. How many live transactions have you conducted on a %live_trading_forex_cfds_frequency% basis?":
          return i18n.t(
            "how-many-live-transactions-have-you-conducted-on-a-basis"
          );
          break;
        default:
          return label;
          break;
      }
    },
    // EGM requested that country dropdown
    // filter results by first character
    // by default it filtes with contain, no matter the character position
    filterByFirstLetter(object, searchTerm) {
      return object.filter((item) =>
        item.display_name.toLowerCase().startsWith(searchTerm.toLowerCase())
      );
    },
    filterByFirstLetterIB(object, searchTerm) {
      return object.filter((item) =>
        item.name.toLowerCase().startsWith(searchTerm.toLowerCase())
      );
    },
    updateForm() {
      localStorage.setItem("storedData", JSON.stringify(this.form));
    },
    updateTradingAccountsPlatform() {
      if (this.trading_accounts.length > 0) {
        this.trading_accounts[0].currency = "";
        delete this.trading_accounts[0].currency;
      }
      this.form.trading_accounts = this.trading_accounts;
      localStorage.setItem("storedData", JSON.stringify(this.form));
    },

    updateTradingAccounts() {
      this.form.trading_accounts = this.trading_accounts;
      localStorage.setItem("storedData", JSON.stringify(this.form));
      console.log("TRADING ACCOUNTS", this.form.trading_accounts);
    },
    checkIfObjectContains(one, two) {
      for (let i in one) {
        if (!two.hasOwnProperty(i) || one[i] !== two[i]) {
          return false;
        }
      }
      return true;
    },
    checkDepositWarning() {
      if (this.form.expected_deposit) {
        this.checkLiquidAssets();
      }
    },
    checkStepNumber() {
      let stepNumber = localStorage.getItem("step");
      return stepNumber;
    },
    // on ICMMU if deposit amount is larger than 30% of the higher value of selected liquid asset, we display an warning message
    // on ICMCapital if deposit amount is larger than 75%
    // user can still submit a form even with this warning
    checkLiquidAssets() {
      let deposit = this.form.expected_deposit;
      if (this.whitelabel === "ICMMU") {
        if (this.form.approx_assets) {
          switch (this.form.approx_assets) {
            case "assets_below_1k":
              if (deposit > 300) {
                this.showDepositWarning = true;
              } else {
                this.showDepositWarning = false;
              }
              break;
            case "assets_1k_5k":
              if (deposit > 1500) {
                this.showDepositWarning = true;
              } else {
                this.showDepositWarning = false;
              }
              break;
            case "assets_5k_10k":
              if (deposit > 3000) {
                this.showDepositWarning = true;
              } else {
                this.showDepositWarning = false;
              }
              break;
            case "assets_10k_25k":
              if (deposit > 7500) {
                this.showDepositWarning = true;
              } else {
                this.showDepositWarning = false;
              }
              break;
            case "assets_25k_50k":
              if (deposit > 15000) {
                this.showDepositWarning = true;
              } else {
                this.showDepositWarning = false;
              }
              break;
            case "assets_50k_75k":
              if (deposit > 22500) {
                this.showDepositWarning = true;
              } else {
                this.showDepositWarning = false;
              }
              break;
            case "assets_75k_100k":
              if (deposit > 30000) {
                this.showDepositWarning = true;
              } else {
                this.showDepositWarning = false;
              }
              break;
            case "assets_above_500k":
              if (deposit > 45000) {
                this.showDepositWarning = true;
              } else {
                this.showDepositWarning = false;
              }
              break;
          }
        }
      }
      if (
        this.whitelabel === "ICMCapital" ||
        this.whitelabel === "ICMMENA" ||
        this.whitelabel === "GCCBrokers" ||
        this.whitelabel === "TradiNext"
      ) {
        if (this.form.liquid_assets) {
          switch (this.form.liquid_assets) {
            case "lt_1000":
              if (deposit > 750) {
                this.showDepositWarning = true;
              } else {
                this.showDepositWarning = false;
              }
              break;
            case "1000_3000":
              if (deposit > 2250) {
                this.showDepositWarning = true;
              } else {
                this.showDepositWarning = false;
              }
              break;
            case "3000_5000":
              if (deposit > 3750) {
                this.showDepositWarning = true;
              } else {
                this.showDepositWarning = false;
              }
              break;
            case "5000_7500":
              if (deposit > 5625) {
                this.showDepositWarning = true;
              } else {
                this.showDepositWarning = false;
              }
              break;
            case "7500_10000":
              if (deposit > 7500) {
                this.showDepositWarning = true;
              } else {
                this.showDepositWarning = false;
              }
              break;
            case "10000_12500":
              if (deposit > 9375) {
                this.showDepositWarning = true;
              } else {
                this.showDepositWarning = false;
              }
              break;
            case "12500_15000":
              if (deposit > 11250) {
                this.showDepositWarning = true;
              } else {
                this.showDepositWarning = false;
              }
              break;
            case "15000_20000":
              if (deposit > 15000) {
                this.showDepositWarning = true;
              } else {
                this.showDepositWarning = false;
              }
              break;
            case "20000_25000":
              if (deposit > 18750) {
                this.showDepositWarning = true;
              } else {
                this.showDepositWarning = false;
              }
              break;
            case "25000_50000":
              if (deposit > 37500) {
                this.showDepositWarning = true;
              } else {
                this.showDepositWarning = false;
              }
              break;
            case "50000_75000":
              if (deposit > 56250) {
                this.showDepositWarning = true;
              } else {
                this.showDepositWarning = false;
              }
              break;
            case "75000_100000":
              if (deposit > 75000) {
                this.showDepositWarning = true;
              } else {
                this.showDepositWarning = false;
              }
              break;
            case "100000_125000":
              if (deposit > 93750) {
                this.showDepositWarning = true;
              } else {
                this.showDepositWarning = false;
              }
              break;
            case "125000_150000":
              if (deposit > 112500) {
                this.showDepositWarning = true;
              } else {
                this.showDepositWarning = false;
              }
              break;
            case "gt_150000":
              if (deposit > 112500) {
                this.showDepositWarning = true;
              } else {
                this.showDepositWarning = false;
              }
              break;
          }
        }
      }
    },
    // onVerify: function (response) {
    //   if (response) {
    //     this.robot = true;
    //     this.next()
    //   }
    // },
    testFields() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter((error) => error["value"].length);
            this.$refs.form.refs[errors[0]["key"]].$el.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }, 100);
        } else {
          this.next();
        }
      });
    },
    async next() {
      this.form.is_finished = true;
      // this trading_accounts is added because on some registrations, there is no field for this
      // but backend require you to send ampty array
      if (!this.form.trading_accounts) {
        this.form.trading_accounts = [];
      }
      this.isDisabled = true;

      // Annual check

      if (this.formType === "annual-check") {
        delete this.form.trading_accounts;
        delete this.form.is_finished;

        this.$store
          .dispatch("annual_check", this.form)
          .then((res) => {
            this.$router.replace("/account-summary");
            this.isDisabled = false;
          })
          .catch((err) => {
            this.isDisabled = false;
            this.$notify({
              group: "foo",
              text: `${Object.entries(err.data)[0][1]}`,
              type: "warn",
            });
          });
        return;
      }

      // IB forms

      if (this.formType === "IB-individual") {
        this.form.language = "en";
        delete this.form.trading_accounts;
        if (
          process.env.VUE_APP_WHITELABEL !== "TD365" &&
          process.env.VUE_APP_WHITELABEL !== "Blackstone" &&
          process.env.VUE_APP_WHITELABEL !== "OneTradingMarkets" &&
          process.env.VUE_APP_WHITELABEL !== "EagleGlobalMarkets" &&
          process.env.VUE_APP_WHITELABEL !== "TradeCoreUK" &&
          process.env.VUE_APP_WHITELABEL !== "TradiNext" &&
          process.env.VUE_APP_WHITELABEL !== "PolarisMarkets"
        ) {
          this.form.whitelabel = process.env.VUE_APP_WHITELABEL;
        }
        if (
          process.env.VUE_APP_WHITELABEL === "TD365" ||
          process.env.VUE_APP_WHITELABEL === "Blackstone" ||
          process.env.VUE_APP_WHITELABEL === "OneTradingMarkets" ||
          process.env.VUE_APP_WHITELABEL === "EagleGlobalMarkets" ||
          process.env.VUE_APP_WHITELABEL === "TradeCoreUK" ||
          process.env.VUE_APP_WHITELABEL === "GCCBrokers" ||
          process.env.VUE_APP_WHITELABEL === "TradiNext"
        ) {
          this.form.password2 = this.form.password_confirmation;
          delete this.form.password_confirmation;
          delete this.form.is_finished;
        }
        if ("skype" in this.form) {
          if (this.form.skype === "") {
            delete this.form.skype;
          }
        }
        if ("addr_line_2" in this.form) {
          if (this.form.addr_line_2 === "") {
            delete this.form.addr_line_2;
          }
        }
        this.$store
          .dispatch(`ib_forms_personal_${this.id + 1}`, this.form)
          .then((res) => {
            if (
              process.env.VUE_APP_WHITELABEL === "TD365" ||
              process.env.VUE_APP_WHITELABEL === "TDSouthAfrica" ||
              process.env.VUE_APP_WHITELABEL === "Blackstone" ||
              process.env.VUE_APP_WHITELABEL === "OneTradingMarkets" ||
              process.env.VUE_APP_WHITELABEL === "EagleGlobalMarkets" ||
              process.env.VUE_APP_WHITELABEL === "TradeCoreUK" ||
              process.env.VUE_APP_WHITELABEL === "GCCBrokers" ||
              process.env.VUE_APP_WHITELABEL === "TradiNext" ||
              process.env.VUE_APP_WHITELABEL === "PolarisMarkets"
            ) {
              this.$router.replace("/ib/summary");
            }
            this.isDisabled = false;
            this.$store.dispatch("show_next_step");
            if (this.id === 1) {
              this.$router.replace("/ib/summary");
            }
          })
          .catch((err) => {
            this.isDisabled = false;
            let errorMesage = `${Object.entries(err.data)[0][1]}`;
            if (
              `${Object.entries(err.data)[0][1]}` === "This field is required."
            ) {
              errorMesage = "Phone number is required";
            }
            this.$notify({
              group: "foo",
              text: errorMesage,
              type: "warn",
            });
          });
        return;
      }

      if (this.formType === "IB-company") {
        delete this.form.trading_accounts;
        if (
          process.env.VUE_APP_WHITELABEL !== "TD365" &&
          process.env.VUE_APP_WHITELABEL !== "Blackstone" &&
          process.env.VUE_APP_WHITELABEL !== "OneTradingMarkets" &&
          process.env.VUE_APP_WHITELABEL !== "EagleGlobalMarkets" &&
          process.env.VUE_APP_WHITELABEL !== "TradiNext" &&
          process.env.VUE_APP_WHITELABEL !== "PolarisMarkets"
        ) {
          this.form.whitelabel = process.env.VUE_APP_WHITELABEL;
        }
        if (
          process.env.VUE_APP_WHITELABEL === "TD365" ||
          process.env.VUE_APP_WHITELABEL === "Blackstone" ||
          process.env.VUE_APP_WHITELABEL === "OneTradingMarkets" ||
          process.env.VUE_APP_WHITELABEL === "EagleGlobalMarkets" ||
          process.env.VUE_APP_WHITELABEL === "TradeCoreUK" ||
          process.env.VUE_APP_WHITELABEL === "TradiNext" ||
          process.env.VUE_APP_WHITELABEL === "PolarisMarkets"
        ) {
          delete this.form.is_finished;
          this.form.language = "en";
          if (this.form.addr_line_2 === "") {
            delete this.form.addr_line_2;
          }
        }
        if (
          process.env.VUE_APP_WHITELABEL === "TDSouthAfrica" ||
          process.env.VUE_APP_WHITELABEL === "TD365" ||
          process.env.VUE_APP_WHITELABEL === "Blackstone"
        ) {
          // TD365, Blackstone and TDSouthAfrica sends langauge 'en' when submiting, but there is no field to select this value
          this.form.language = "en";
          if (this.id === 1) {
            delete this.form.addr_line_1;
            delete this.form.company_name;
            delete this.form.country;
            delete this.form.is_finished;
            delete this.form.password_confirmation;
            delete this.form.tax_id;
            delete this.form.website;
          }
        }
        if (
          process.env.VUE_APP_WHITELABEL === "EagleGlobalMarkets" ||
          process.env.VUE_APP_WHITELABEL === "TradeCoreUK"
        ) {
          this.form.language = "en";
        }

        this.$store
          .dispatch(`ib_forms_company_${this.id + 1}`, this.form)
          .then((res) => {
            this.isDisabled = false;
            this.$store.dispatch("show_next_step");
            if (this.id === 1) {
              this.$router.replace("/ib/summary");
            }
          })
          .catch((err) => {
            this.isDisabled = false;
            let errorMesage = `${Object.entries(err.data)[0][1]}`;
            if (
              `${Object.entries(err.data)[0][1]}` === "This field is required."
            ) {
              errorMesage = "Phone number is required";
            }
            this.$notify({
              group: "foo",
              text: errorMesage,
              type: "warn",
            });
          });
        return;
      }

      if (this.formType === "Lead") {
        delete this.form.trading_accounts;
        this.form.whitelabel = process.env.VUE_APP_WHITELABEL;

        this.$store
          .dispatch("lead_forms", this.form)
          .then((res) => {
            this.isDisabled = false;
            this.showSuccessfulModal = true;
            localStorage.clear();
          })
          .catch((err) => {
            this.isDisabled = false;
            this.$notify({
              group: "foo",
              text: `${Object.entries(err.data)[0][1]}`,
              type: "warn",
            });
          });
        return;
      }

      // Demo account
      if (this.demoForm) {
        // remove password_confirmation from form
        delete this.form.password_confirmation;

        if (
          process.env.VUE_APP_WHITELABEL === "EagleGlobalMarkets" ||
          process.env.VUE_APP_WHITELABEL === "TradeCoreUK"
        ) {
          this.form.trading_accounts[0].currency = "USD";
          this.form.trading_accounts[0].group = null;
        }
        // if (process.env.VUE_APP_WHITELABEL === 'VCGMarkets' ) this.form.trading_accounts = [{backend: "MT5 Demo", currency: "USD"}]
        if (process.env.VUE_APP_WHITELABEL === "VCGMarkets") {
          let tradingPlatforms = this.$store.getters.get_demo_trading_platforms;
          // take first object
          let object = tradingPlatforms[0];

          this.form.trading_accounts = [
            { backend: object.backend.name, currency: "USD" },
          ];
        }
        if (
          process.env.VUE_APP_WHITELABEL === "ICMAP" ||
          process.env.VUE_APP_WHITELABEL === "ICMMU" ||
          process.env.VUE_APP_WHITELABEL === "ICMVC" ||
          process.env.VUE_APP_WHITELABEL === "ICMTrader"
        ) {
          // show upload docs modal

          let tradingPlatforms = this.$store.getters.get_demo_trading_platforms;
          let object = tradingPlatforms.find((o) => o.backend.type === "MT4");
          this.form.trading_accounts = [
            { backend: object.backend.name, currency: "USD" },
          ];
        }

        if (
          (process.env.VUE_APP_WHITELABEL === "ICMCapital" &&
            this.demoCompany) ||
          (process.env.VUE_APP_WHITELABEL === "GCCBrokers" && this.demoCompany)
        ) {
          this.showTheForm = false;
          this.showLoadingSpinner = true;
          if (process.env.VUE_APP_WHITELABEL === "GCCBrokers") {
            this.form.business_group = "corporate";
          }
          this.$store
            .dispatch("send_demo_company_signup_data", this.form)
            .then((res) => {
              this.isDisabled = false;
              this.$router.replace("/");
              localStorage.removeItem("storedData");
            })
            .catch((err) => {
              this.showTheForm = true;
              this.showLoadingSpinner = false;
              this.isDisabled = false;
              let errorMesage = `${Object.entries(err.data)[0][1]}`;
              if (
                `${Object.entries(err.data)[0][1]}` ===
                "This field is required."
              ) {
                errorMesage = "Phone number is required";
              }
              this.$notify({
                group: "foo",
                text: errorMesage,
                type: "warn",
              });
            });
        } else {
          if (
            process.env.VUE_APP_WHITELABEL === "EagleGlobalMarkets" ||
            process.env.VUE_APP_WHITELABEL === "TradeCoreUK"
          ) {
            this.showTheForm = false;
            this.showLoadingSpinner = true;
            this.$store
              .dispatch("send_demo_signup_data_with_whitelabel", this.form)
              .then((res) => {
                console.log("POST 2", res);
                this.isDisabled = false;
                localStorage.removeItem("storedData");
                this.$store
                  .dispatch("get_backends")
                  .then((res) => {
                    let tradingAccountData =
                      this.$store.getters.get_demo_backend_accounts;
                    let userData = this.$store.getters.get_user_data;
                    // console.log('user', userData);
                    // console.log('usermail', userData.email);
                    // console.log('trading account',tradingAccountData);
                    const dxtradeexist = tradingAccountData.some(
                      (object) => object.backend.type === "DXTrade"
                    );
                    if (dxtradeexist && this.pageRoute.includes("/mobile")) {
                      let tradingAccountExternalID =
                        userData.trading_accounts[0].external_id;
                      // console.log('tradingAccountExternalID',tradingAccountExternalID);
                      this.$store
                        .dispatch(
                          "generate_sso_token",
                          tradingAccountExternalID
                        )
                        .then(() => {
                          //  console.log('redirect');
                          let token = this.$store.getters.get_sso_token;
                          //  console.log('krajnji token', token)
                          window.location.href = `https://dxmobile.page.link?link=https://alpha-eagleglobalmarkets.tradecore.io/mobile/auth_success?token=${this.$store.getters.get_sso_token}&apn=com.devexperts.dxmarket.client&ibi=com.devexperts.mercury`;
                          // if(process.env.VUE_APP_WHITELABEL === 'EagleGlobalMarkets'){
                          //   window.location.href = `https://dxmobile.page.link?link=https://alpha-eagleglobalmarkets.tradecore.io/mobile/auth_success?token=${this.$store.getters.get_sso_token}&apn=com.devexperts.dxmarket.client&ibi=com.devexperts.mercury`;
                          // } else {
                          //   window.location.href = `https://dxmobile.page.link?link=https://dxtrade.tradecore.io/mobile/auth_success?token=${this.$store.getters.get_sso_token}&apn=com.devexperts.dxmarket.client&ibi=com.devexperts.mercury`;
                          // }
                        })
                        .catch((err) => {
                          console.log("error");
                        });
                    } else {
                      this.$router.replace("/");
                    }
                  })
                  .catch((err) => {
                    this.showTheForm = true;
                    this.showLoadingSpinner = false;
                    console.error(err);
                  });
              })
              .catch((err) => {
                this.showTheForm = true;
                this.showLoadingSpinner = false;
                this.isDisabled = false;
                let errorMesage = `${Object.entries(err.data)[0][1]}`;
                if (
                  `${Object.entries(err.data)[0][1]}` ===
                  "This field is required."
                ) {
                  errorMesage = "Phone number is required";
                }
                this.$notify({
                  group: "foo",
                  text: errorMesage,
                  type: "warn",
                });
              });
          } else {
            this.showTheForm = false;
            this.showLoadingSpinner = true;
            if (process.env.VUE_APP_WHITELABEL === "GCCBrokers") {
              this.form.business_group = "default";
            }
            // // check for addr_country
            if (!this.form.addr_country) {
              this.form.addr_country = await this.getCountryName();
              //   take from geo
            }

            this.$store
              .dispatch("send_demo_signup_data", this.form)
              .then((res) => {
                console.log("POST 1111", res);
                this.isDisabled = false;
                if (process.env.VUE_APP_WHITELABEL === "Blackstone") {
                  this.$router.replace("/welcome");
                  localStorage.removeItem("storedData");
                } else if (process.env.VUE_APP_WHITELABEL === "GCCBrokers") {
                  this.$store.commit("SET_GCC_DEMO_POPUP", true);
                  this.$router.replace("/");
                  localStorage.removeItem("storedData");
                } else {
                  this.$router.replace("/");
                }
              })
              .catch((err) => {
                this.showLoadingSpinner = false;
                this.showTheForm = true;
                this.isDisabled = false;
                let errorMesage = `${Object.entries(err.data)[0][1]}`;
                if (
                  `${Object.entries(err.data)[0][1]}` ===
                  "This field is required."
                ) {
                  errorMesage = "Phone number is required";
                }
                this.$notify({
                  group: "foo",
                  text: errorMesage,
                  type: "warn",
                });
              });
            // .then(() => {
            //   if (process.env.VUE_APP_WHITELABEL === "VCGMarkets") {
            //     this.$store
            //       .dispatch("websdk_token")
            //       .then((res) => {
            //         console.log("websdk_token", res);
            //       })
            //       .catch((e) => {
            //         console.log("websdk_token", e);
            //       });
            //   }
            // });
          }
        }
      }
      //${Object.entries(err.data)[0][1]}
      // Live account from demo
      if (this.openLiveForm) {
        // remove password_confirmation from form
        delete this.form.password_confirmation;
        if (this.registrationNumberOfSteps === 0) {
          this.form.is_finished = true;
          // for orbit invest BE requested that we return all the accounts, not only the first
          // so we make a orbit_platforms that will grab all the trading account in array of objects
          // we then loop through all of the objects and insert each one in the form.trading_accounts
          if (
            process.env.VUE_APP_WHITELABEL === "OrbitInvest" ||
            process.env.VUE_APP_WHITELABEL === "VCGMarkets"
          ) {
            let orbit_platforms = this.get_trading_platforms;
            this.form.trading_accounts = [];
            for (let i = 0; i < orbit_platforms.length; i++) {
              this.form.trading_accounts.push({
                backend: orbit_platforms[i].backend.name,
                currency: "USD",
              });
            }
          }

          this.data = {
            data: this.form,
            method: "PUT",
          };
          this.$store
            .dispatch("send_signup_data_open_live_account_step_one", this.data)
            .then((res) => {
              this.isDisabled = false;
              if (this.$store.getters.get_suitable.result) {
                this.$router.replace("/");
                this.$store.dispatch("get_backends");
              } else {
                this.$router.replace("/auth/login");
              }
              this.$store.commit("SHOW_NEXT_STEP");
            })
            .catch((err) => {
              this.isDisabled = false;
              this.$notify({
                group: "foo",
                text: err,
                type: "warn",
              });
            });
        }
        // if the form has more than 1 step and this was not the last step (demo > live)
        if (
          this.$store.getters.show_next_step < this.registrationNumberOfSteps
        ) {
          // ICMCapital requested that on open live account for company
          // we check if the user has checked at least one checkmark for company_speculative_gains or company_trade_risks
          // so we first check if its the right form and that the account is type company
          // then we check if the 2 fields exists in the form and if any of their values is true
          // if it is we pass the form
          // else we show the error message end "return" to disable the form submition
          if (
            process.env.VUE_APP_WHITELABEL === "ICMCapital" &&
            this.$store.getters.show_next_step == 1 &&
            this.registrationNumberOfSteps == 2 &&
            this.pageRoute.includes("/open-live-account")
          ) {
            if (this.getUserData.extra.client_type === "company") {
              if (this.form.hasOwnProperty("company_speculative_gains")) {
                if (this.form.company_speculative_gains === true) {
                  this.formPass = true;
                }
              }
              if (this.form.hasOwnProperty("company_trade_risks")) {
                if (this.form.company_trade_risks === true) {
                  this.formPass = true;
                }
              }

              if (this.formPass === false) {
                this.showRequiredMessage = true;
                const element = document.querySelector("body");
                element.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });

                this.isDisabled = false;
                return;
              } else {
                this.showRequiredMessage = false;
              }
            }
          }

          // the user now chooses the trading account from the form so no need to send default
          // if (process.env.VUE_APP_WHITELABEL === 'ICMAP' ||  process.env.VUE_APP_WHITELABEL === 'ICMMU' ||  process.env.VUE_APP_WHITELABEL === 'ICMVC'){
          // this.form.trading_accounts = [{backend: 'ICM Capital live', currency: "USD"}]
          // this.form.trading_accounts = [{backend: this.get_trading_platforms[0].backend.name, currency: "USD"}]
          // }

          this.form.is_finished = false;
          const formData = { ...this.form };
          delete formData.password_confirmation;
          this.data = {
            data: formData,
            method: "POST",
          };
          if (process.env.VUE_APP_WHITELABEL === "Blackstone") {
            // console.log(this.$store.getters.get_user_data)
            // console.log(this.get_profile)
            delete this.data.data.username;
            delete this.data.data.password;
            delete this.data.data.addr_country;
            delete this.data.data.email;
            delete this.data.data.first_name;
            delete this.data.data.last_name;
            delete this.data.data.password_confirmation;
            this.data.method = "PUT";
            this.data.data.language = "en";
            // this.data.data.extra = this.$store.getters.get_user_data.extra
            this.data.data.country_of_birth =
              this.get_profile.address.addr_country;
            this.data.data.trading_accounts[0].group = null;
          }

          // VCGMarkets client  asked simple signup so you populate trading_accounts on behalf of a user on demo signup but this the first and only case where it works like this. In all other cases you should obey instructions from step_meta
          if (
            process.env.VUE_APP_WHITELABEL === "VCGMarkets" ||
            process.env.VUE_APP_WHITELABEL === "PolarisMarkets"
          ) {
            this.data.data.trading_accounts = [];
          }
          this.$store
            .dispatch("send_signup_data_open_live_account_step_one", this.data)
            .then((res) => {
              console.log("POST 1", res);
              this.isDisabled = false;
              this.$store.dispatch("show_next_step");
            })
            .catch((err) => {
              this.isDisabled = false;
              const t = this.$t.bind(this);
              var errMessage = `${Object.entries(err.data)[0][1]}`;
              if (
                errMessage ===
                "Must be at least 18 years old in order to sign up."
              ) {
                errMessage = t("you-have-to-be-over-18-to-continue");
              } else {
                errMessage = `${Object.entries(err.data)[0][1]}`;
              }
              this.$notify({
                group: "foo",
                text: errMessage,
                type: "warn",
              });
            });
        }
        if (
          this.$store.getters.show_next_step ===
            this.registrationNumberOfSteps &&
          this.registrationNumberOfSteps !== 0
        ) {
          // if (process.env.VUE_APP_WHITELABEL === 'ICMAP' ||  process.env.VUE_APP_WHITELABEL === 'ICMMU' ||  process.env.VUE_APP_WHITELABEL === 'ICMVC'){
          //   this.form.trading_accounts.push({backend: this.get_trading_platforms[0].backend.name, currency: "USD"})
          // }
          this.form.is_finished = true;
          if (process.env.VUE_APP_WHITELABEL === "Blackstone") {
            this.form.country_of_birth = this.get_profile.address.addr_country;
            delete this.form.addr_country;
            delete this.form.email;
            delete this.form.first_name;
            delete this.form.last_name;
            delete this.form.password;
            delete this.form.password_confirmation;
            delete this.form.username;
            this.form.trading_accounts[0].group = null;
          }

          // if (
          //   this.whitelabel === "FXViewEU" ||
          //   this.whitelabel === "FXViewGlobal"
          // ) {
          //   this.form.trading_accounts[0].group = "demo\\act_forex";
          // }

          if (this.whitelabel === "GCCBrokers" && this.stage === "production") {
            let groupName = "";
            if (this.form.account_type === "pro") {
              if (this.form.trading_accounts[0].currency === "USD") {
                groupName = "real\\PRO-A100-US";
              } else if (this.form.trading_accounts[0].currency === "EUR") {
                groupName = "real\\PRO-A100-EU";
              }
            }
            if (this.form.account_type === "standard") {
              if (this.form.trading_accounts[0].currency === "USD") {
                groupName = "real\\STD-A100-US";
              } else if (this.form.trading_accounts[0].currency === "EUR") {
                groupName = "real\\STD-A100-EU";
              }
            }

            this.form.trading_accounts[0].group = groupName;
          }
          if (process.env.VUE_APP_WHITELABEL === "VCGMarkets") {
            let platforms = this.get_trading_platforms;

            this.form.trading_accounts = [];
            for (let i = 0; i < platforms.length; i++) {
              this.form.trading_accounts.push({
                backend: platforms[i].backend.name,
                currency: "USD",
              });
            }
          }

          this.$store
            .dispatch("send_signup_data_open_live_account_step_two", this.form)
            .then((res) => {
              console.log("PUT 2");
              this.isDisabled = false;
              this.$store.dispatch("profile");
              if (this.$store.getters.get_suitable.result) {
                this.$router.replace("/");
                this.$store.dispatch("get_backends");
              } else {
                if (process.env.VUE_APP_WHITELABEL === "TD365") {
                  this.showTd365UnsuitableModal = true;
                } else {
                  this.$store.dispatch("logout");
                  if (this.whitelabel === "VCGMarkets") {
                    //   redirect to external login
                    const url = process.env.VUE_APP_API_URL.includes("dev.")
                      ? "https://dev-website.anttix.app/auth/login"
                      : "https://vcgmarkets.com/auth/login";
                    return window.open(url, "_self");
                  }
                  this.$router.replace("/auth/login");
                }
              }
            })
            .catch((err) => {
              this.isDisabled = false;
              this.$notify({
                group: "foo",
                text: err,
                type: "warn",
              });
            });
        }
      } else {
        if (this.liveForm) {
          // if (this.form.password_confirmation) {
          //   delete this.form.password_confirmation;
          //   localStorage.setItem("storedData", JSON.stringify(this.form));
          // }
          if (
            process.env.VUE_APP_WHITELABEL === "TDSouthAfrica" ||
            process.env.VUE_APP_WHITELABEL === "VCGMarkets"
          ) {
            this.form.whitelabel = process.env.VUE_APP_WHITELABEL;
            if (this.trading_accounts[0].backend !== "") {
              this.form.trading_accounts = this.trading_accounts;
            }
          }
          // Logic for live form with steps
          if (this.registrationNumberOfSteps === 0) {
            this.form.is_finished = true;
            this.data = {
              data: this.form,
              method: "PUT",
            };
            if (
              process.env.VUE_APP_WHITELABEL === "TDSouthAfrica" ||
              process.env.VUE_APP_WHITELABEL === "TradeCoreUK"
            ) {
              this.$store
                .dispatch(
                  "send_signup_data_step_one_with_whitelabel",
                  this.data
                )
                .then((res) => {
                  this.isDisabled = false;
                  if (this.$store.getters.get_suitable.result) {
                    this.$router.replace("/");
                  } else {
                    if (this.whitelabel === "VCGMarkets") {
                      //   redirect to external login
                      const url = process.env.VUE_APP_API_URL.includes("dev.")
                        ? "https://dev-website.anttix.app/auth/login"
                        : "https://vcgmarkets.com/auth/login";
                      return window.open(url, "_self");
                    }
                    this.$router.replace("/auth/login");
                  }
                  this.$store.commit("SHOW_NEXT_STEP");
                })
                .catch((err) => {
                  this.isDisabled = false;
                  this.$notify({
                    group: "foo",
                    text: err,
                    type: "warn",
                  });
                });
            } else {
              this.$store
                .dispatch("send_signup_data_step_one", this.data)
                .then((res) => {
                  this.isDisabled = false;
                  if (this.$store.getters.get_suitable.result) {
                    this.$router.replace("/");
                  } else {
                    if (this.whitelabel === "VCGMarkets") {
                      //   redirect to external login
                      const url = process.env.VUE_APP_API_URL.includes("dev.")
                        ? "https://dev-website.anttix.app/auth/login"
                        : "https://vcgmarkets.com/auth/login";
                      return window.open(url, "_self");
                    }
                    this.$router.replace("/auth/login");
                  }
                  this.$store.commit("SHOW_NEXT_STEP");
                })
                .catch((err) => {
                  this.isDisabled = false;
                  this.$notify({
                    group: "foo",
                    text: err,
                    type: "warn",
                  });
                });
            }
          }

          // premestili smo logiku za ovo u mounted()
          // if(process.env.VUE_APP_WHITELABEL === 'TD365'){
          //   if(this.$store.getters.show_next_step === 2  && this.form.addr_country !== 'AU'){
          //     this.registrationNumberOfSteps = this.registrationNumberOfSteps - 1
          //   }
          // }

          if (
            this.$store.getters.show_next_step < this.registrationNumberOfSteps
          ) {
            // this will scroll the screen to the top of the page
            const element = document.getElementById("router-view");
            element.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });

            this.form.is_finished = false;
            // remove password_confirmation from form
            const formData = { ...this.form };
            delete formData.password_confirmation;
            this.data = {
              data: formData,
              method: "POST",
            };
            console.log("POST 2");
            if (process.env.VUE_APP_WHITELABEL === "TradeCoreUK") {
              this.form.whitelabel = process.env.VUE_APP_WHITELABEL;
            }
            if (
              process.env.VUE_APP_WHITELABEL === "TDSouthAfrica" ||
              process.env.VUE_APP_WHITELABEL === "TradeCoreUK"
            ) {
              this.$store
                .dispatch(
                  "send_signup_data_step_one_with_whitelabel",
                  this.data
                )
                .then((res) => {
                  this.isDisabled = false;
                  this.$store.dispatch("show_next_step");
                })
                .catch((err) => {
                  let error = err;
                  let errorMessage = `${Object.entries(err.data)[0][1]}`;
                  if (errorMessage !== undefined && errorMessage !== null) {
                    error = errorMessage;
                  }
                  this.isDisabled = false;
                  this.$notify({
                    group: "foo",
                    text: error,
                    type: "warn",
                  });
                });
            } else {
              this.$store
                .dispatch("send_signup_data_step_one", this.data)
                .then((res) => {
                  this.isDisabled = false;
                  this.$store.dispatch("show_next_step");
                })
                .catch((err) => {
                  let error = err;
                  let errorMessage = `${Object.entries(err.data)[0][1]}`;
                  if (errorMessage !== undefined && errorMessage !== null) {
                    error = errorMessage;
                  }
                  this.isDisabled = false;
                  this.$notify({
                    group: "foo",
                    text: error,
                    type: "warn",
                  });
                });
            }
          }
          if (
            this.$store.getters.show_next_step ===
              this.registrationNumberOfSteps &&
            this.registrationNumberOfSteps !== 0
          ) {
            this.form.is_finished = true;
            const combinedData = { ...this.form, ...this.data }; // BR#22 Sunny, getting sumsub link from backend through this.data and sending it to client portal frontend by combining it to this.form 30/09/2024
            if (process.env.VUE_APP_WHITELABEL === "TDSouthAfrica") {
              this.$store
                .dispatch("send_signup_data_step_two_without_reg_id", this.form)
                .then((res) => {
                  this.isDisabled = false;
                  if (this.$store.getters.get_suitable.result) {
                    this.$router.replace("/");
                  } else {
                    if (this.whitelabel === "VCGMarkets") {
                      //   redirect to external login
                      const url = process.env.VUE_APP_API_URL.includes("dev.")
                        ? "https://dev-website.anttix.app/auth/login"
                        : "https://vcgmarkets.com/auth/login";
                      return window.open(url, "_self");
                    }

                    this.$router.replace("/auth/login");
                  }
                })
                .catch((err) => {
                  this.isDisabled = false;
                  this.$notify({
                    group: "foo",
                    text: err,
                    type: "warn",
                  });
                });
            } else if (process.env.VUE_APP_WHITELABEL === "TradeCoreUK") {
              this.$store
                .dispatch(
                  "send_signup_data_step_two_with_whitelabel",
                  this.form
                )
                .then((res) => {
                  this.isDisabled = false;
                  if (this.$store.getters.get_suitable.result) {
                    this.$router.replace("/");
                  } else {
                    if (this.whitelabel === "VCGMarkets") {
                      //   redirect to external login
                      const url = process.env.VUE_APP_API_URL.includes("dev.")
                        ? "https://dev-website.anttix.app/auth/login"
                        : "https://vcgmarkets.com/auth/login";
                      return window.open(url, "_self");
                    }
                    this.$router.replace("/auth/login");
                  }
                })
                .catch((err) => {
                  this.isDisabled = false;
                  this.$notify({
                    group: "foo",
                    text: err,
                    type: "warn",
                  });
                });
            } else {
              this.$store
                .dispatch("send_signup_data_step_two", combinedData) // BR#22 Sunny, here the sumsub link is going in the second page of ICMMU signup, 30/09/2024
                .then((res) => {
                  this.isDisabled = false;
                  if (
                    process.env.VUE_APP_WHITELABEL === "ICMMU" ||
                    process.env.VUE_APP_WHITELABEL === "ICMVC"
                  ) {
                    this.$store.commit("SET_ICM_PROOF_MODAL", "ID Proof");
                  }
                  if (this.$store.getters.get_suitable.result) {
                    this.$router.replace("/");
                  } else {
                    if (process.env.VUE_APP_WHITELABEL === "TD365") {
                      this.showTd365UnsuitableModal = true;
                    } else {
                      if (this.whitelabel === "VCGMarkets") {
                        //   redirect to external login
                        const url = process.env.VUE_APP_API_URL.includes("dev.")
                          ? "https://dev-website.anttix.app/auth/login"
                          : "https://vcgmarkets.com/auth/login";
                        return window.open(url, "_self");
                      }
                      this.$router.replace("/auth/login");
                    }
                  }
                })
                .catch((err) => {
                  this.isDisabled = false;
                  this.$notify({
                    group: "foo",
                    text: err,
                    type: "warn",
                  });
                })
                .then(() => {
                  if (
                    process.env.VUE_APP_WHITELABEL === "OneTradingMarkets" ||
                    process.env.VUE_APP_WHITELABEL === "VCGMarkets"
                  ) {
                    this.$store
                      .dispatch("websdk_token")
                      .then((res) => {
                        console.log("websdk_token", res);
                      })
                      .catch((e) => {
                        console.log("websdk_token", e);
                      });
                  }
                });
            }
          }
        }
      }
    },
    addDate(event, name) {
      let year = event.slice(0, 4);
      let month = event.slice(5, 7);
      let day = event.slice(8, 10);
      this.form[name] = [day, month, year].join("/");
    },
    // "vue-phone-number-input" emits event that has a isValid value
    // if isValid is false, we call a validatePhone validations that will return false
    // and a error message
    phoneValidateCheck(phone) {
      this.phoneValid = phone.isValid;
      this.form.telephone = phone.formattedNumber;
      localStorage.setItem("storedData", JSON.stringify(this.form));
    },
    translateNameString(field) {
      const t = this.$t.bind(this);
      field.choices[0].display_name = t("Individual");
      field.choices[1].display_name = t("Corporate");
    },

    // validate emaildomain spelling typo yahoo, gmail, hotmail
    //BR#21  Mbeng Atemson 28/08/2024 
    autoCorrectDomain() {
      const domainCorrections = {
      'yaho.com': 'yahoo.com',
      'yahooo.com': 'yahoo.com',
      'yahoo.co': 'yahoo.com',
      'yahoo.cm': 'yahoo.com',
      'gmail.co': 'gmail.com',
      'gmai.com': 'gmail.com',
      'gmail.cm': 'gmail.com',
      'hotmai.com': 'hotmail.com',
      'hotmail.co': 'hotmail.com',
      'hotmeil.com': 'hotmail.com',
      'gmail.comm': 'gmail.com',
      'yahoo.comm': 'yahoo.com',
      'hotmail.comm': 'hotmail.com',

    };

    const email = this.form.email ? this.form.email.trim() : '';
    const emailParts = email.split('@');

    if (emailParts.length === 2) {
      let emailDomain = emailParts[1].toLowerCase();

      if (domainCorrections[emailDomain]) {
        emailDomain = domainCorrections[emailDomain];
        this.$set(this.form, 'email', `${emailParts[0]}@${emailDomain}`);
      }
    }
  },
  toggleEmailField() {
      this.shouldShowEmail = !this.shouldShowEmail;
    },
  handleBlur() {
    this.autoCorrectDomain();
    this.$refs.emailProvider.reset();
   
  }
  },
};
</script>
<style scoped>
  .maintenance-notice {
    background-color: #f8d7da;
    color: #721c24;
    padding: 15px;
    text-align: center;
    font-weight: bold;
    border: 1px solid #f5c6cb;
    border-radius: 5px;
    margin-bottom: 20px;
    font-size: 20px;
   
  }
 
  .text-dark-maroon {
    color: #800000; /* Dark Maroon */
    white-space: nowrap; /* Prevent text wrapping */
   
  }
 
 
  .full-name-higlight {
    font-size: 22px;
    font-weight: bold;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
   
  }
 
 
 
  @media (min-width: 640px) {
    .sm\:leading-5 {
        line-height: 1.25rem;
    }
    .full-name-input {
        width: 205%;
       
      }
    .full-name-input1 {
      width: 100%;
      margin-right: -5rem
     
      }
    .full-name-label {
    margin-right: -0.1rem;
    white-space: nowrap; /* Prevent text wrapping */
      }
    .flex {
        gap: 0.5rem; /* Add spacing between items on larger screens */
       
      }
  }
 
  @media (min-width: 640px) {
      .sm\:text-sm {
          font-size: 0.875rem;
      }
      .full-name-input {
        width: 205%;
   
      }
      .full-name-input1 {
        width: 100%;
       
      }
      .flex {
        gap: 0.5rem; /* Add spacing between items on larger screens */
        /*justify-content: end;*/
      }
  }
 
  </style>